import React from 'react';
import Block from '../LongScroll/Block';

const Blocks = props => {

	let pages = props.pages

	if ( props.pages && props.pages.length > 0  && props.pages[ 0 ].template === 'hero-tabs' ) {
	    pages = pages.filter( a => a.template === 'hero-tabs' )
	}

	if ( props.section === 'stock' && props.sub  ) {
	    pages = pages.filter( a => a.template === 'hero' || a.template === 'equities'  )
	}

	return (
		pages.map( ( a, i ) => {
			return (
				<Block key={ a.id + '-' + i } history={ props.history } page={ a } newslimit={ props.newslimit } section={ props.section } slug={ props.slug } sub={ props.sub } limit={ props.newslimit } />
			)
		} ) 
	)
	
}

export default Blocks
