import React, { Fragment } from 'react';
import NewsList from '../News/NewsList';
import NewsListAll from '../News/NewsListAll';

const News = props => (
  <Fragment>
    <div id="news" className="products-wrapper" style={{ 
      backgroundColor:'#fff', 
      color:props.structure.color, 
      padding:'20px 0 0 0',
      margin:'0 0 20px 0',
    }} >
      { props.limit > 4 
      ? <NewsListAll
        limit={ props.limit || 4 }
        companyName={ props.companyName }
        backgroundColor={ props.structure.backgroundColor }
        color={ props.structure.color }
        backFromPageTo="back" 
      />
      : <NewsList
        limit={ props.limit || 4 }
        companyName={ props.companyName }
        backgroundColor={ props.structure.backgroundColor }
        color={ props.structure.color }
        backFromPageTo="back" 
      />
      }
    </div>
  </Fragment>
)

export default News
