import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'; // must import Router, else switch doesn't work
import LongScroll from '../LongScroll/LongScroll';
import Equities from '../Equities/Equities';
import Security from '../Equities/Security';
//import FrontPage from '../Intro/FrontPage';
//import Quotations from '../Intro/Quotations';
//import News from '../Intro/News';
import BlogPage from '../Blogs/BlogPage';
import NewsPage from '../News/NewsPage';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Cookies from '../Cookies/Cookies';
import '../css/index.css';


const App = ( props ) => {

  const [ offset, setOffset ] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      setOffset( window.pageYOffset )
    }
  }, []);

  return (
    <Fragment>
      <Router basename={ process.env.PUBLIC_URL }>
        <Fragment>
        <Switch>
          {/*
          <Route exact path="/" render={ routeProps => <LongScroll { ...routeProps } section="front-page" />} />
          */}
          <Route exact path="/pre-market/" render={ routeProps => <LongScroll { ...routeProps } section="stock" />} />
          <Route exact path="/stock/" render={ routeProps => <LongScroll { ...routeProps } section="stock" />} />
          <Route exact path="/stock/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="stock" />} />
          <Route exact path="/stock/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="stock" />} />

          <Route exact path="/news/" render={ routeProps => <LongScroll { ...routeProps } newslimit="200" section="news" />} />
          <Route exact path="/news/:slug/" component={ NewsPage }/>

          <Route exact path="/services/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />
          <Route exact path="/services/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />
          <Route exact path="/services/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />

          <Route exact path="/contact-us/" render={ routeProps => <LongScroll { ...routeProps } section="contact-us" />} />
          <Route exact path="/contact-us/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="contact-us" />} />
          <Route exact path="/contact-us/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="contact-us" />} />

          <Route exact path="/privanet-group/" render={ routeProps => <LongScroll { ...routeProps } section="privanet-group" />} />
          <Route exact path="/privanet-group/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="privanet-group" />} />
          <Route exact path="/privanet-group/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="privanet-group" />} />

          <Route exact path="/share-exchange/" render={ routeProps => <LongScroll { ...routeProps } section="share-exchange" />} />
          <Route exact path="/share-exchange/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="share-exchange" />} />
          <Route exact path="/share-exchange/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="share-exchange" />} />

          <Route exact path="/blogs/:slug/" component={ BlogPage }/>
          {/*
          <Route exact path="/equities/" component={ Equities } />
          <Route exact path="/equities/:tab/:abbreviation/" component={ Security } />} />
          <Route exact path="/equities/:tab/" render={ routeProps => <Equities { ...routeProps } tabs={ true } />} />
          */}
          <Redirect from="*" to="/privanet-group/" />

        </Switch>
        {/*
        <Route path="/:route/" component={ Header }/>
        */}
        <Footer/>
        <Route path="/:route/" render={ routeProps => <Header { ...routeProps } offset={ offset } />} />
        <Route exact path="/" render={ routeProps => <Header { ...routeProps } offset={ offset } />} />
        <Cookies/>
        </Fragment>
      </Router>
    </Fragment>
  ) 
}

export default App 
