import React from 'react';
import { Link, Router } from 'react-router-dom'; 
import parse from 'html-react-parser';
import styled from 'styled-components'

const StyledImg = styled.img`${props => props.myStyle}`;

const parseHtml = ( htmlText ) => {

    let copyHtml = parse( ( htmlText || '' ), {
        
      replace: function ( domNode ) {
        if ( domNode.attribs && domNode.attribs.href ) {
            if ( domNode.attribs.href.substring( 0, 4 ) === 'http' ) {
                domNode.attribs.target = '_blank'
                console.log( 'domNode.attribs',domNode.attribs )
            }

        	if ( domNode.attribs.href.substring( 0, 1 ) === '/' ) {
                //console.log( ' *** parseHtml *** ', domNode.attribs.href.substring( 0, 4 ), domNode.children[ 0 ].data );
                if ( !domNode || !domNode.children || domNode.children.length === 0 || !domNode.children[ 0 ].hasOwnProperty( 'data' ) ) {
                    // no nothing
                } else if ( domNode.children[ 0 ].data !== undefined ) {
                    return <Link to={ domNode.attribs.href }>{ domNode.children[ 0 ].data }</Link>
                } else if ( domNode.children[ 0 ].name === 'img' ) {
                    //console.log( ' -- img child -- ', domNode.children[ 0 ].attribs.src, domNode.children[ 0 ].attribs.style );
                    return <Link to={ domNode.attribs.href } style={{ border:'none' }} >
                        <StyledImg src={ domNode.children[ 0 ].attribs.src } myStyle={ domNode.children[ 0 ].attribs.style }  />
                    </Link>
                }
        	}
        }         
      } 
        
    } )

    // remove first div with intro and image, if exists
    if ( copyHtml && copyHtml.length > 0 && copyHtml[ 0 ].type === 'div' ) {
        copyHtml.splice( 0, 1 );
    }
    // remove first h2 with title, if exists
    if ( copyHtml && copyHtml.length > 0 && copyHtml[ 0 ].type === 'h2' ) {
        copyHtml.splice( 0, 1 );
    }

    return copyHtml

}

export default parseHtml
