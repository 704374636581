import React, { Fragment } from 'react';
import EmployeesList from '../Employees/EmployeesList';

const Employees = props => (
  <Fragment>
    <div id="employees" className="products-wrapper" style={{ backgroundColor:props.structure.backgroundColor, color:props.structure.color, padding:'40px 0 20px 0' }} >
      <div className="products" >
        <h1 style={{ padding:'0px 30px 0 30px', margin:'0', lineHeight:'48px', boxSizing:'border-box', userSelect:'none' }} >Ihmiset</h1>
      </div>
      <EmployeesList
        limit="99"
        backgroundColor={ props.structure.backgroundColor }
        color={ props.structure.color }
        backFromPageTo="back" 
      />
    </div>
  </Fragment>
)

export default Employees
