import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom';
//import '../css/fonts.css';
//import '../css/commonStyles.css';
import parse from 'html-react-parser';
import Utils from '../Utils/Utils';

export const Title = ( props )  => (
	<tr className="row">
		<td className="col" colSpan="3">
			<h3 className="" style={{ padding:'5px', fontSize:'2rem', lineHeight:'2rem', fontWeight:'700', margin:'30px 0 30px -2px' }} >{ props.children }</h3>
		</td>
	</tr>
)

export const Tabs = ( props )  => {
	let renderTab = props.renderTab || ( () => { return true } )
	return props.keys.map( ( tabKey, tabIndex ) => (
		renderTab( tabKey ) && 
		<Link key={ tabKey } to={ props.baseUrl + tabKey } style={{ 
			opacity:( props.curTabKey === tabKey ? '1' : '0.5' ), 
			borderBottom:( props.curTabKey === tabKey ? '4px solid #7dd2f7' : '3px solid rgba(0,0,0,0)' ),
			display:'inline-block', 
			userSelect:'none',
			fontSize:'1.5rem',
			padding:'0 2px 3px 2px', 
			margin:'0 5px',
			color:'black',
			fontWeight:'700' 
		}} >
		{ props.titles[ tabIndex ] }
		</Link>
	) )
}

export const ColTitle = ( props )  => {
	return (
	<td className={"col bold " + props.className } style={{ 
		padding:'5px', 
		lineHeight:'16px', 
		minWidth:props.width,
		width:props.width, 
		wordWrap:'break-word',
		textAlign:( props.type === 'number' || props.type === 'date' ? 'right' : 'left' ) 
	}} >{ props.children ? parse( props.children ) : '' }</td>
	)
}

export const LinkCol = ( props )  => (
	props.type === 'number' 
	? <td className="col upper" style={{ verticalAlign:'middle', padding:'5px', textAlign:'right' }} ><Link to={ props.link }>{ Utils.format( props.children, 2 ) }</Link></td>
	: <td className="col upper" style={{ verticalAlign:'middle', padding:'5px', textAlign:'left'  }} ><Link to={ props.link }>{ props.children }</Link></td>
)

// 

export const Col = ( props )  => {
	const link = ( e ) => {
		if ( props.link ) {
			props.setRoute( props.link )
		}
	}
	//return (
	if ( props.type === 'number' ){
		return( <td 
				onClick={ link } 
				className={ "col upper " + props.className } 
				style={{ verticalAlign:'middle', padding:'5px 10px', textAlign:'right', whiteSpace:'nowrap' }} 
			>{ Number( props.children ) === 0 ? '' : Utils.format( props.children, props.decimals ) }</td> )
	} else if ( props.type === 'date' ){
		return( <td 
				onClick={ link } 
				className={"col upper " + props.className } 
				style={{ verticalAlign:'middle', padding:'5px 10px', textAlign:'right', whiteSpace:'nowrap' }} 
				>{ Utils.isoDateToFi( props.children ) }</td> )
	} else if ( props.type === 'delete' ){
		if ( props.delete === true ) {
			return( <td 
					className={"col upper " + props.className } 
					style={{ verticalAlign:'middle', padding:'5px 10px', textAlign:'left'  }} >
				<button 
					className="solid" 
					disabled={ props.deletedIds.hasOwnProperty( props.rowId ) } 
					style={{ opacity:( props.deletedIds.hasOwnProperty( props.rowId ) ? '0.5' : '1' ) }}  
					onClick={ () => { props.cancelOrder( props.children ) } } 
				>Poista</button>
			</td> )
		} else {
			return( <td 
						className={"col upper " + props.className } 
						style={{ verticalAlign:'middle', padding:'5px', textAlign:'left'  }} 
					></td> )
		}
	} else {
		return( 
			<td 
				onClick={ link } 
				className={"col " + props.className } 
				style={{ verticalAlign:'middle', padding:'5px', wordWrap:'break-word', width:props.width, textAlign:'left', whiteSpace:'nowrap'  }} 
			>
				<div style={{ display:'inline-block', whiteSpace:'nowrap' }} >
					{ props.children }
				</div>
			</td> 
			)
	}
	//)
}

export const MainTable = ( props )  => (
	<main style={{ backgroundColor:'white', textAlign:'center', margin:'0', paddingTop:'4px' }} >
		<div className="column" style={{ padding:props.padding || '0 30px', boxSizing:'border-box' }} >
			<div className="section width-3 intro-actions intro-actions-2" style={{ textAlign:'center' }} >
				<table className="table" style={{ ...props.tableStyle, borderCollapse:'collapse', margin:'0 auto', paddingTop:'0' }} >
				<tbody>
					{ props.children }
				</tbody>
				</table>
				<div style={{ height:'20px', boxSizing:'border-box' }} />
			</div>
		</div>
	</main>
)

export const Group = ( props ) => {
	return (
	<Fragment key={ props.id } >

		{/* stockGroup title */}
		{ props.title ? <Title>{ props.title }</Title> : null }

		{/* col titles */}
		<tr className="row" style={{ borderBottom:'2px solid #aaa' }} >{ props.columns.map( ( c, i ) => ( 
			<ColTitle key={ i } className={ c.className } type={ c.type } >{ c.title }</ColTitle>
		) ) }
		</tr>

		{/* rows */}
		{ props.data.map( ( row, i ) => { 
		return (
			<tr key={ row.id } className="row highlight" style={{ borderBottom:'1px solid #aaa', height:'36px' }} >
			{ props.columns.map( ( g, j ) => { 
			let colVal = row[ g.key ]
			if ( g.key === 'coupon_frequency' ) {
				if ( colVal === 'P1M' ) {
					colVal = 'Kuukausittain'
				} else if ( colVal === 'P3M' ) {
					colVal = 'Neljännesvuosittain'
				} else if ( colVal === 'P6M' ) {
					colVal = 'Puolivuosittain'
				} else if ( colVal === 'P12M' ) {
					colVal = 'Vuosittain'
				}
			}
			return (
				<Col key={ j } className={ g.className } setRoute={ props.setRoute } link={ props.staticlink + encodeURIComponent( row[ props.linkattribute ] ) } type={ g.type } >
					{ colVal }
				</Col>
			) } ) }
			</tr> 
		) } ) }

	</Fragment>
	)
}

export const OrdersGroup = ( props ) => (
	<Fragment>

		{/* stockGroup title */}
		{ props.title ? <Title>{ props.title }</Title> : null }
		

		{/* col titles */}
		<tr className="row highlight" style={{ borderBottom:'2px solid #aaa' }} >{ props.columns && props.columns.map( ( c, i ) => ( 
			<ColTitle key={ i } className={ c.className } width={ c.width } type={ c.type } >{ c.fi }</ColTitle>
		) ) }
		</tr>

		{/* rows */}
		{ props.data && props.data.length > 0 && props.data.map( ( row, i ) => ( 
			row.order_status === props.status && row.base_type === props.baseType
			? <tr key={ row.id } className="row" style={{ borderBottom:'1px solid #aaa', height:'36px' }} >
			{ props.columns && props.columns.map( ( g, j ) => (
				<Col 
					key={ j } 
					link={ props.staticlink ? props.staticlink + row[ props.linkattribute ] : null } 
					type={ g.type }
					decimals={ g.decimals }
					className={ g.className } 
					width={ g.width } 
					delete={ props.delete } 
					deletedIds={ props.deletedIds }
					rowId={ row.id }
					cancelOrder={ props.cancelOrder }
				>
					{ row[ g.key ] } 
				</Col>
			) ) }
			</tr>
			: null 
		) ) }

	</Fragment>
)
