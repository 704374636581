import React from 'react';

const GraphTabs = ( props )  => {
	//let renderTab = props.renderTab || ( () => { return true } )
	return props.data.map( ( a, i ) => (
		<div 
			key={ i }
			onClick={ e => { props.setCurrentTabIndex( i ) } } 
			style={{ 
				opacity:( props.currentTabIndex === i ? '1' : '0.5' ), 
				borderBottom:( props.currentTabIndex === i ? '4px solid #7dd2f7' : '4px solid rgba(0,0,0,0)' ),
				display:'inline-block', 
				cursor:'pointer',
				userSelect:'none',
				fontSize:'12px',
				lineHeight:'12px',
				padding:'0 0 4px 0', 
				margin:'0 0 6px 10px',
				color:'black',
				fontWeight:'700' 
		}} >
		{ props.data[ i ].title }
		</div>
	) )
}

export default GraphTabs
