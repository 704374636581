import React,{ Fragment } from 'react';

import bgImage from '../img/joni-bw.jpg';
const bgUrl = `${ bgImage }`

const SecurityAdBanner = props => {
	return (
		<div style={{ position:'relative', width:'100%', height:'190px', marginTop:'40px' }} >
			<div style={{ position:'absolute', top:0, left:'50%', transform:'translateX(-50%)', width:'100%', maxWidth:'900px', margin:'0 auto' }} >
				<div style={{ backgroundColor:'rgb(230 241 244)', width:'100%', padding:'5px 30px', boxSizing:'border-box' }} >
					<h4><b>Keskustele aiheesta asiantuntijan kanssa</b></h4>
					<p>
						desk@privanet.fi<br/>
						+358 (0)10 219 0520
					</p>
				</div>
			</div>
		</div>
	)
}

export default SecurityAdBanner
