import React, { Fragment, useState, useEffect } from 'react';
import Store from '../Store/Store';
import ActionButton from '../ActionButton/ActionButton';


const Cookies = props => {

  const cookies = Store.get( 'cookies' )

  const [ update, setUpdate ] = useState(0);

  const setAllCookies = () => {
    Store.set( 'cookies', 'all' )
    setUpdate( update + 1 )
  }

  const setEssentialCookies = () => {
    Store.set( 'cookies', 'essential' )
    setUpdate( update + 1 )
  }

  console.log( 'c', cookies )

  if ( cookies ) {
    return null
  } else {
    return (
      <div style={{ 
          position:'fixed', bottom:0, left:'50%', transform:'translateX(-50%)',
          width:'100%', maxWidth:'800px',
          background:'rgb(230 241 244)', border:'1px solid rgba(0,0,0,0.25)',
          boxShadow:'0 4px 6px rgba(0,0,0,0.15)',
          padding:'10px 20px', margin:'10px',
          fontSize:'0.75rem',
      }}>
        Käytämme sivustollamme evästeitä käyttäjäkokemuksen parantamiseksi sekä sisällön ja mainonnan kohdentamiseen. Hyväksy-painikkeella pyydämme suostumustasi muihin kuin sivuston toiminnan kannalta välttämättömiin evästeisiin. Välttämättömät evästeet ovat pakollisia verkkosivuston toiminnan kannalta ja tekevät verkkosivustosta käyttökelpoisen sallimalla perustoimintoja kuten sivulla siirtymisen ja sivuston suojattujen alueiden käytön. 
        <a href="https://privanet.fi/documents/kayttoehdot.pdf" target="_blank" style={{ fontWeight:'bold', padding:'0 10px' }} >
        Lue lisää evästeistä ja henkilötietojen käytöstä.
        </a>

        <div style={{ position:'relative', width:'100%', textAlign:'center' }} >
          <div style={{ display:'inline-block', paddingTop:'10px' }} >
            <div onClick={ setAllCookies } style={{ display:'inline-block', cursor:'pointer', padding:'0 10px' }} >
              <ActionButton title="Hyväksy evästeet" style={{ 
                  display:'inline-block', width:'190px', 
                  background:'linear-gradient(180deg, #6b6b72 0%, #446 100%)' 
                }} />
            </div>
            <div onClick={ setEssentialCookies } style={{ display:'inline-block', cursor:'pointer', padding:'0 10px' }} >
              <ActionButton title="Hylkää" style={{ 
                  display:'inline-block', width:'110px', 
                  background:'linear-gradient(180deg, #90a4ae 0%, #455a64 100%)' 
                }} />
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default Cookies
