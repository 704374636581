import React, { Fragment, useEffect, useState } from 'react';
import Store from '../Store/Store';
import NewsCard from '../News/NewsCard';
import ActionButton from '../ActionButton/ActionButton';
import styled from 'styled-components'
const Grid = styled.div`
  display: grid;
  grid-template-columns:auto;
  justify-items: center;
  @media only screen and ( min-width: 640px) {
	  grid-template-columns:auto auto;
  }
  @media only screen and ( min-width: 850px) {
	  grid-template-columns:auto auto auto;
  }
  @media only screen and ( min-width: 1100px) {
	  grid-template-columns:auto auto auto auto;
	  
  }
`;

const NewsListAll = props => {

	const [ min, setmin ] = useState( 0 )
	//const min = props.min || 0

	let limit = min + ( props.limit || 4 )
	let news = Store.get( 'news' ) || []
	if ( news === null || news === null || news.length === 0 ) {
		return null
	}
	if ( props.companyName ) {
	    news = news.filter( a => a.company_name === props.companyName )
	}

	const nextNews = () => {
		let nextMin = min + 1
		if ( nextMin > ( news.length - 4 ) ) nextMin = news.length - 4
		setmin( nextMin )
	}

	const prevNews = () => {
		let nextMin = min - 1
		if ( nextMin < 0 ) nextMin = 0
		setmin( nextMin )
	}

	return ( 
    	<div className="products" style={{ backgroundColor:'#fff' }} >
	    	<Grid 
	    		style={{ 
	    			backgroundColor:'#fff', color:props.color || '#000' , 
	    			verticalAlign:'top', 
	    			textAlign:'center', margin:'0', boxSizing:'border-box', 
	    		}} 
    		>
	    		{ news.map( ( row, i ) => {
	    			if ( i < Number( min ) || i >= Number( limit ) ) return <Fragment key={ row.slug  + '-' + i } />
    				return (
			    		<NewsCard 
			    			key={ row.slug  + '-' + i }
			    			row={ row }
			    			slug={ row.slug }
			    			backFromPageTo={ props.backFromPageTo }
			    		/>
			    	)
		    	} ) }
		    </Grid>
			{/*
		    <div style={{ textAlign:'center', padding:'20px' }} >
				<ActionButton title="Uutisarkistoon" style={{ display:'inline-block', width:'170px', background:'linear-gradient(180deg, #6b6b72 0%, #446 100%)' }} />
		    </div>
		    */}
		</div>
	)
}

export default NewsListAll
