import React from 'react'

const ActionButton = props => {
  const baseStyle={
    opacity:'100%', userSelect:'none', 
    width:'150px',
    height:'42px',
    margin:'12px 0 0 0', 
    position:'relative',
    textAlign:'left', fontFamily:'gothamlight', fontSize:'0.75rem', fontWeight:'700', 
    padding:'6px 8px 0 22px', 
    borderRadius:'21px', 
    verticalAlign:'top',
    transform:'translateY(-5px)',
    background:'linear-gradient(180deg, #FFAA4C 0%, #FF8E25 100%)', 
    color:'white',
    overflow: 'hidden',
    boxSizing:'border-box',
  }
  let useStyle={ ...baseStyle, ...props.style }
  return(
    <div onClick={ props.onClick } style={ useStyle } >
      {/*
      <div style={{ position:'absolute', left:'16px', top:'15px', width:'200px', height:'auto', margin:0, padding:0, textTransform:'uppercase', lineHeight:'12px' }}>
      */}
      <div style={{ padding:'8px', textTransform:'uppercase', lineHeight:'12px' }}>
        { props.title }
      </div>
      {/*
      <svg style={{ position:'absolute', right:'10px', top:'9px' }} width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M36 18C36 8.05858 27.9414 0 18 0C8.05858 0 0 8.05858 0 18C0 27.9414 8.05858 36 18 36C27.9414 36 36 27.9414 36 18ZM34.0418 18C34.0418 26.8117 26.8117 34.0418 18 34.0418C9.18828 34.0418 1.95816 26.8117 1.95816 18C1.95816 9.18828 9.18828 1.95816 18 1.95816C26.8117 1.95816 34.0418 9.18828 34.0418 18Z" fill="white"/>
        <path d="M17.3935 8L28 18.5L17.3935 29L16 27.5438L25.0581 18.5L16 9.4562L17.3935 8Z" fill="white"/>
        <path d="M26 19L8 19V17L26 17V19Z" fill="white"/>
      </svg>              
      */}
    </div>
  )
}

export default ActionButton
