import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Request from '../Request/Request';
import Store from '../Store/Store';
import Utils from './Utils/Utils';
import OrdersTable from './OrdersTable';
import SecurityButtons from './SecurityButtons';
import SecuritySummary from './SecuritySummary';
import SecurityAdBanner from './SecurityAdBanner';
import TradeStop from './TradeStop';
import GraphSet from './GraphSet';
import NewsList from '../News/NewsList';
import Block from '../LongScroll/Block';



class Security extends React.Component {

	constructor( props ) {
		super( props )
		this.state = {
			tmp:null,
		};
	}

	componentDidMount() {
    	document.documentElement.scrollTop = 0; 
    	document.body.scrollTop = 0; // for Safari
		//let abbr = this.props.match.params.abbreviation
		let abbr = this.props.sub
		let stockApiRequest = "stock/" + encodeURIComponent( abbr )
		Request.get( 
			stockApiRequest, 
			result => { 
				Store.set( 'security-' + abbr, result.data ) 
				this.setState( { tmp:null } )
				Request.get( 
					"quotes/" + result.data.id, 
					result => { 
						Store.set( 'quotes-' + abbr, result.data ) 
						this.setState( { tmp:null } )
					},
					null,
					"https://privanet.fi/premarket/api/"
				)
			},
			null,
			"https://privanet.fi/premarket/api/"
		)
		Request.get( 
			"orders/" + abbr, 
			result => { 
				Store.set( 'orders-' + abbr, result.data ) 
				this.setState( { tmp:null } )
			},
			null,
			"https://privanet.fi/premarket/api/"
		)
	}

	render( props ) {

		//let currentTab = this.props.match.params.tab
		//let abbr = this.props.match.params.abbreviation
		let currentTab = this.props.slug
		let abbr = this.props.sub

		let items = Store.get( 'security-' + abbr ) 
		let orders = Store.get( 'orders-' + abbr ) 
		let data = Store.get( 'quotes-' + abbr )

	    let allPages = Store.get( 'pages' ) || []
	    let disclaimerPages = allPages.filter( a => a.section === 'company' && a.slug === 'disclaimer' )
	    let disclaimerPage = disclaimerPages[ 0 ]
	    let aboutPages = allPages.filter( a => a.section === 'company' && a.slug === 'about-company' )
	    let aboutPage = aboutPages[ 0 ]

	    let company
	    //console.log( 'items.name', items )
	    if ( items && items != null ) {
		    let allCompanies = Store.get( 'companies' ) || []
		    let oneCompany = allCompanies.filter( a => a.name === items.name )
		    company = oneCompany.length > 0 ? oneCompany[ 0 ] : null
		    //console.log( 'company.copy', company.copy )
	    }

		let itemsLoaded = () => {
			return items && items != null 
		}

		let ordersLoaded = () => {
			return orders && orders != null 
		}

		let dataLoaded = () => {
			return data && data != null 
		}

		let ordersArray = [
			{ buy_amount:'', buy_price:'', sell_amount:'', sell_price:'' },
			{ buy_amount:'', buy_price:'', sell_amount:'', sell_price:'' },
			{ buy_amount:'', buy_price:'', sell_amount:'', sell_price:'' },
			{ buy_amount:'', buy_price:'', sell_amount:'', sell_price:'' },
			{ buy_amount:'', buy_price:'', sell_amount:'', sell_price:'' },
		]

		if ( ordersLoaded() ) {
			orders.buy.map( ( c, i ) => {
				ordersArray[ i ].buy_amount = c.shares_amount
				ordersArray[ i ].buy_price = Utils.format( c.shares_price, 2 )
			} )
			orders.sell.map( ( c, i ) => {
				ordersArray[ i ].sell_amount = c.shares_amount
				ordersArray[ i ].sell_price = Utils.format( c.shares_price, 2 )
			} )
		}


		let tradeStopOn = false
		if ( items && items.hasOwnProperty( 'description' ) ) {

			let description = items.description,
					tradestoptext = '',
					tradestop = new Date( '1975-01-01' ),
					tradestart = new Date( '2050-01-01' )

			if ( description != null ) {
			    tradestoptext = description.tradestoptext || '';
			    tradestop = description.tradestop ? new Date( description.tradestop ) : new Date( '1975-01-01' );
			    tradestart = description.tradestart ? new Date( description.tradestart ) : new Date( '2050-01-01' );
			}

			tradeStopOn = ( tradestoptext !== '' && Date.now() > tradestop && Date.now() < tradestart )

		}

		return (
			<Fragment>
			<div className="products" >
				<div style={{ width:'100%', marginTop:0, textAlign:'left', backgroundColor:'white' }} >
					<div style={{ display:'block', width:'100%', maxWidth:'1100px', padding:0, margin:'0 auto', backgroundColor:'white', textAlign:'center' }} >
						<Link to={ '/stock' + '/' + ( currentTab === 'PK' ? 'SU' : currentTab ) + '/' } style={{ 
							userSelect:'none',
							display:'inline-block',
							padding:'10px 30px', 
							boxSizing:'border-box', 
							color:'black',
							fontWeight:'700',
							width:'100%',
							boxSizing:'border-box'
						}} >
						<div style={{ position:'relative', padding:'0 30px', margin:'0', width:'100%', boxSizing:'border-box' }} >
							<h2 style={{ display:'block', padding:'15px 0 0 0', margin:'0', fontFamily:'futuraheavy', fontSize:'2.5rem', lineHeight:'2.5rem', textTransform:'uppercase' }} >
								{ itemsLoaded() && items.name }
							</h2>
							{/*
							<div style={{ position:'absolute', left:0, top:'12px' }}>
								<span className="material-icons" style={{ fontSize:'32px' }} >
									arrow_back
								</span>
							</div>
							*/}
						</div>
						</Link>

						{ dataLoaded() && items && 
						<SecuritySummary items={ items } />
						}


						<div className="security-grid" >

							<div style={{ gridArea:'orders', margin:'0', padding:'0', overflow:'hidden', boxSizing:'border-box', }} >

								{ !tradeStopOn && 
								<OrdersTable 
									ordersArray={ ordersArray } 
								/>
								}
							</div>

							{ dataLoaded() && currentTab !== 'BONDS' && 
							<div style={{ gridArea:'graph', margin:'0', padding:'0', overflow:'hidden', boxSizing:'border-box', }} >
								<GraphSet data={ data } />
							</div>
							}

						</div>


						<TradeStop
							rowData={ items }
							currentTab={ currentTab }
						/>

						<SecurityButtons
							rowData={ items }
							currentTab={ currentTab }
						/>

					</div>
				</div>
			</div>


			{ dataLoaded() && items && company && company.hasOwnProperty( "copy_left" ) && company.copy_left !== null && company.copy_left !== '' &&
			<div className="products" style={{ background:'rgb(230, 241, 244)', maxWidth:'100%', marginBottom:'30px' }} >
				<div style={{ width:'100%', margin:'0 auto', maxWidth:'1100px', textAlign:'left' }} >
					<div style={{ display:'block', width:'100%', maxWidth:'1100px', padding:0, boxSizing:'border-box', textAlign:'center' }} >
						<div className="company-info-grid" style={{ paddingTop:'20px' }} >
							<div className="company-info" style={{ borderRight:'1px solid #ddd' }} >{ parse( company.copy_left || '' ) }</div>
							<div className="company-info" >{ parse( company.copy_right || '' ) }</div>
						</div>
					</div>
				</div>
			</div>
			}

			{ dataLoaded() && items && 
			<div style={{ textAlign:'center', fontWeight:'900', padding:'0' }} >
				<h3 style={{ display:'block', fontWeight:'700', padding:'10px 0 20px 0', margin:'0'  }} >
					Uutiset
				</h3>
				<NewsList
					limit={ 4 }
					companyName={ items.name || '' }
					backgroundColor="white"
					color="#000"
					backFromPageTo="back" 
				/>
			</div>
			}


			<div className="products" >
				<div style={{ width:'100%', marginTop:0, textAlign:'left', backgroundColor:'white' }} >
					<div style={{ display:'block', width:'100%', margin:'0 auto', maxWidth:'1100px', padding:0, boxSizing:'border-box', backgroundColor:'white', textAlign:'center' }} >

						{ dataLoaded() && items && company && company.hasOwnProperty( "youtube_link" ) && company.youtube_link !== null && company.youtube_link !== '' &&
						<div style={{ textAlign:'center', fontWeight:'900', padding:'0' }} >
							<h3 style={{ display:'block', textAlign:'center', fontWeight:'700', padding:'50px 0 20px 0', margin:'0'  }} >
								Esittely
							</h3>
							<div style={{ maxWidth:'800px', margin:'0px auto' }} >
								<div style={{ position:'relative', paddingBottom:'75%', height:'0px' }} >
									<iframe
										style={{
											position:"absolute",
											top:0,
											left:0,
											width: "100%",
											height: "100%"
											}}
										title="video"
										src={`https://www.youtube.com/embed/${company.youtube_link}`}
										frameBorder="0"
									/>
									</div>
							</div>
						</div>
						}

						{ dataLoaded() && items && company && company.hasOwnProperty( "files" ) && company.files.length > 0 &&
						<div style={{ textAlign:'center', fontWeight:'900', padding:'30px 0 20px 0' }} >

							<h3 style={{ display:'block', textAlign:'center', fontWeight:'700', padding:'50px 0 20px 0', margin:'0'  }} >
								Liitteet
							</h3>
							<table style={{ width:'400px', textAlign:'left', margin:'0 auto' }} ><tbody>{ company.files.map( a => {
								const fileName = a.split("/").pop()
								return ( 
									<a key={ fileName } href={ a } target="_blank" >
										<tr><td>
											<div style={{ width:'50px', height:'50px', lineHeight:'50px', fontSize:'0.75rem', fontWeight:'bold', textAlign:'center', backgroundColor:'rgb(230 241 244)', borderRadius:'25px' }} >
												PDF
											</div>
											</td>
												<td>{ fileName }</td>
											</tr>
									</a> 
								) 
							} ) }</tbody></table>

						</div>
						}

						<SecurityAdBanner/>

					</div>

				</div>

			</div>
			<div className="products" >
		      <Block key={ disclaimerPage.id } page={ disclaimerPage } title="" section={ 'company' } />
			</div>
			</Fragment>
		)
	}
}

export default Security

