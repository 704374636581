import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom';
import Store from '../Store/Store';
import Data from '../Data/Data-dynamic';
import BlogCard from '../Blogs/BlogCard';

class BlogsList extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
			//blogs:Data().blogs || [],
			blogs:Data.blogs || [],
		};
	}

	componentDidMount() {
		/*
		Data().fetch( ( data ) => {
			this.setState( { blogs:data.blogs } )
		} )
		*/
	}

	render() {
		let limit = this.props.limit || 12

		//let blogs = Data().blogs || []
		//let blogs = this.state.blogs
		let blogs = Store.get( 'blogs' ) || []

		if ( blogs === null || blogs === null || blogs.length === 0 ) {
			return null
		}

		return ( 
        	<div className="products" >
		    	<div style={{ backgroundColor:this.props.backgroundColor || '#fff', color:this.props.color || '#000' , verticalAlign:'top', textAlign:'left', marginLeft:'20px', marginRight:'40px', boxSizing:'border-box', }} >

		    		{ blogs.map( ( row, i ) => {
		    			//console.log( 'blogRow', row )
		    			if ( i >= Number( limit ) ) return <Fragment key={ row.slug  + '-' + i } />
	    				return (
    					<Fragment key={ row.slug  + '-' + i } >
			    		<Link 
 				    		draggable="false" 
				    		to={{
							  pathname:'/blogs/' + row.slug + '/',
							  state: {
							    backFromPageTo:this.props.backFromPageTo
							  }
							}} 
				    		className="blogs-list-block"
			    		>

			    		<BlogCard 
			    			row={ row }
			    		/>
			    		
				    	</Link>
    					</Fragment>

				    	)

			    	} ) }

			    </div>
			</div>
		)
	}
}

export default BlogsList
