import React, { useEffect } from 'react';
import Store from '../Store/Store';
import Blocks from '../LongScroll/Blocks';

const LongScroll = props => {

	useEffect( () => {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}, [ props.section ] )

	// slug for tabs
	let slug = null
	if ( props && props.match && props.match.params && props.match.params.slug ) {
		slug = props.match.params.slug
	}
	// "sub" slug for sub-tab-pages
	let sub = null
	if ( props && props.match && props.match.params && props.match.params.sub ) {
		sub = props.match.params.sub
	}

	let allPages = Store.get( 'pages' ) || []
	let pagesObject = Store.get( 'pagesObject' ) || {}
	//let pages = allPages.filter( a => a.section === props.section )
	let pages = pagesObject[ props.section ]

	return(
  		<Blocks history={ props.history } section={ props.section } pages={ pages } pagesObject={ pagesObject } newslimit={ props.newslimit } slug={ slug } sub={ sub } />
  	)
}

export default LongScroll
