import React from 'react';
import Utils from './Utils/Utils';

const SecuritySummary = props => (
	<div style={{ fontSize:'1rem', textAlign:'center', margin:'30px 0 40px 0' }} >
		<div style={{ display:'inline-block' }} >
			<table style={{ borderCollapse:'collapse', fontSize:'1.25rem' }}   >
				<thead>
					<tr style={{ fontWeight:'700', borderBottom:'1px solid black' }} >
						<td style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "Viim." }</td>
						<td style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "Päivä" }</td>
						<td style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "Markkina-arvo" }</td>
						<td className='show-on-medium' style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "Liikevaihto" }</td>
						<td className='show-on-medium' style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "Tulos" }</td>
						<td className='show-on-large' style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "PS" }</td>
						<td className='show-on-large' style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "PE" }</td>
						<td className='show-on-large' style={{ textAlign:'center', padding:'5px 10px', borderBottom:'1px solid black' }} >{ "PB" }</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td style={{ textAlign:'center', padding:'5px 10px' }} >{ Utils.format( props.items.quotation, 2) }</td>
						<td style={{ textAlign:'center', padding:'5px 10px', whiteSpace:'nowrap' }} >{ Utils.isoDateToFi( props.items.value_date ) }</td>
						<td style={{ textAlign:'center', padding:'5px 10px' }} >{ Utils.format( props.items.value, 2) }</td>
						<td className='show-on-medium' style={{ textAlign:'center', padding:'5px 10px' }} >{ Utils.format( props.items.turnover, 2) }</td>
						<td className='show-on-medium' style={{ textAlign:'center', padding:'5px 10px' }} >{ Utils.format( props.items.result, 2) }</td>
						<td className='show-on-large' style={{ textAlign:'center', padding:'5px 10px' }} >{ Utils.format( props.items.ps, 2) }</td>
						<td className='show-on-large' style={{ textAlign:'center', padding:'5px 10px' }} >{ Utils.format( props.items.pe, 2) }</td>
						<td className='show-on-large' style={{ textAlign:'center', padding:'5px 10px' }} >{ Utils.format( props.items.pb, 2) }</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
)

export default SecuritySummary
