import React from 'react';
import { Link } from 'react-router-dom'; 
import Equities from '../Equities/Equities';
import parse from 'html-react-parser';
import parseHtml from '../parseHtml/parseHtml';
import getArticleAndImage from '../News/getArticleAndImage';
import NewsBannerBySlug from '../News/NewsBannerBySlug';
import ActionButton from '../ActionButton/ActionButton';
import styled from 'styled-components'

const StyledFlexDiv = styled.div`
  display:block; padding:0;
  @media only screen and ( min-width: 640px) {
      display:flex; padding:0; box-sizing:border-box;
  }
`;


const MainEquities = props => {
	// console.log( 'props.structure', props.structure )
  const { newsArticle, imageUrl } = getArticleAndImage( props.structure.columnSlug )
  let leadHtml = parseHtml( newsArticle.lead || '' )
	return (
	  <StyledFlexDiv className="products-wrapper" style={{ marginBottom:'40px' }} >
	    <div style={{ 
	      width:'100%', height:'580px', position:'relative', backgroundColor:'#fff', 
	    }} >
	    	<div style={{ position:'absolute', width:'100%', maxWidth:'640px', margin:'0 auto', right:0, top:0 }} >
			<Equities style={{ width:'100&', maxWidth:'640px', marginTop:'-20px', backgroundColor:'white' }} showTabs={ false } showPk={ false } title="PRIVANETIN SEURATUIMMAT" key={ props.page.id } history={ props.history } slug={ props.slug } sub={ props.sub } page={ props.page } structure={ props.structure } />
			</div>
	    </div>

	    <div style={{ 
	    	width:'100%', height:'580px', position:'relative', 
	    	backgroundColor:'#fff' 
	      //backgroundSize:'cover', backgroundPosition:'50% 0', 
	      //backgroundImage:'linear-gradient(rgba(21, 45, 85, 0.05), rgba(21, 45, 85, 0.3), rgba(21, 45, 85, 0.4), rgba(21, 45, 85, 0.05)), url('+(newsArticle.news_img || newsArticle.company_img)+')' 
	    }} >
		<Link to={ '/news/' + newsArticle.slug + '/' } >
		    <div style={{ 
		      width:'100%', height:'480px', position:'relative', 
		      backgroundColor:'#fff', 
		    }} >
				<div style={{ 
				width:'100%', height:'580px', maxWidth:'640px', overflowX:'hidden',
				padding:'140px 50px 10px 40px', textAlign:'center', color:'white', 
				textShadow:'0 1px 3px rgba(0,0,0,0.25)', boxSizing:'border-box', overflowX:'hidden', overflowY:'hidden', 
				backgroundSize:'cover', backgroundPosition:'50% 0', 
				backgroundImage:'linear-gradient(rgba(21, 45, 85, 0.05), rgba(21, 45, 85, 0.3), rgba(21, 45, 85, 0.4), rgba(21, 45, 85, 0.05)), url('+(newsArticle.news_img || newsArticle.company_img)+')' 
				}} >
			        <h3 
			        	style={{ 
			        		textTransform:'uppercase', textAlign:'center', 
			        		fontFamily:'futuraheavy', fontWeight:'900', letterSpacing:'1.5px', 
			        		color:'white', marginBottom:'40px', boxSizing:'border-box' 
			        	}} 
		        	>
			          { newsArticle.title }
			        </h3>
			        <div style={{ boxSizing:'border-box' }} >
			        	{ leadHtml }
			        </div>
			        <div style={{ padding:'30px 0 20px 0', boxSizing:'border-box' }} >
			          <ActionButton title="LUE LISÄÄ" style={{ display:'inline-block', width:'120px' }} />
			        </div>

				</div>
	      </div>
	    </Link>
	    </div>
	  </StyledFlexDiv>


	)
}

export default MainEquities
