import Store from '../Store/Store';

const getArticleAndImage = ( slug ) => {
	// article by slug
	let allNews = Store.get( 'news' ) || []
	let newsBySlug = allNews.filter( a => a.slug === slug )
	let newsArticle = newsBySlug.length > 0 ? newsBySlug[ 0 ] : {}
	// get news hero files and the first image for background
    let allPages = Store.get( 'pages' ) || []
    let frontPage = allPages.filter( a => a.section === 'news' && a.template === 'hero' )
    let allImages = frontPage && frontPage.length > 0 ? frontPage[ 0 ].files : []
    let imageUrl = allImages[ 0 ]
    console.log( 'newsArticle, imageUrl', newsArticle, imageUrl )
	return { newsArticle, imageUrl }
}

export default getArticleAndImage
