import React from 'react';
import Logo from './Logo/Logo';


const TradeStop = props => {
	//console.log( 'TradeStop props', props )
	if ( !props.rowData || !props.rowData.hasOwnProperty( 'description' ) ) {
		return null
	}
	let d = props.rowData.description,
			tradestoptext = '',
			tradestop = new Date( '1975-01-01' ),
			tradestart = new Date( '2050-01-01' ),
			notetext = '',
			notestart = new Date( '1975-01-01' ),
			noteend = new Date( '2050-01-01' ),
			tradestoplinktext = '',
			tradestoplink = '';

	if ( d != null ) {
	    tradestoptext = d.tradestoptext || '';
	    tradestop = d.tradestop ? new Date( d.tradestop ) : new Date( '1975-01-01' );
	    tradestart = d.tradestart ? new Date( d.tradestart ) : new Date( '2050-01-01' );
	    notetext = d.notetext || '';
	    notestart = d.notestart ? new Date( d.notestart ) : new Date( '1975-01-01' );
	    noteend = d.noteend ? new Date( d.noteend ) : new Date( '2050-01-01' );
	    tradestoplinktext = d.tradestoplinktext || '';
	    tradestoplink = d.tradestoplink || '';
	}

	if ( props.rowData && props.rowData != null && ( notetext || tradestoptext || tradestoplinktext ) ) {
		return (
			<div style={{ width:'100%', backgroundColor:'white', textAlign:'left' }} >
				<div style={{ display:'inline-block', boxSizing:'border-box', backgroundColor:'white', fontSize:'1.25rem', textAlign:'left', marginLeft:'20px' }} >

					{ notetext !== ''  && 
					<div style={{ padding:'10px', margin:'0 30px 0 0', fontWeight:'bold', backgroundColor:'white' }} >
						<Logo style={{ verticalAlign:'middle' }} >note</Logo>
						<div style={{ display:'inline-block', marginLeft:'5px', verticalAlign:'top' }}>{ notetext }</div>
					</div>
					}

					{ tradestoptext !== '' && Date.now() > tradestop && Date.now() < tradestart && 
					<div style={{ padding:'10px', margin:'15px 30px 0 0', fontWeight:'bold', backgroundColor:'white' }} >
						{ tradestoptext }
					</div>
					}

					{ tradestoplinktext !== '' && tradestoplink !== '' && Date.now() > tradestop && Date.now() < tradestart && 
				        <a 
				        	href={ tradestoplink } 
				        	target="_blank" 
				        	rel="noopener noreferrer"
				        	style={{ color:'black', display:'inline-block', padding:'5pt 20pt', margin:'0 10px 0 0', backgroundColor:'white' }} 
			        	>
					        { tradestoplinktext }
				        </a>
					}


				</div>
			</div>
		) 
	} else { 
		return null 
	}
}

export default TradeStop
