import React from 'react';
import { Area, AreaChart, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import RenderTooltip from './RenderTooltip';

const Graph = props => {
	// http://recharts.org/en-US/api
	// https://d3js.org/
	// https://github.com/d3/d3-shape#areas
	// https://github.com/recharts/recharts/blob/master/demo/component/AreaChart.js
	// console.log( 'Graph', props.key, props.chartData )
	/*
		<ResponsiveContainer 
			id="chart" 
			width={ props.width } 
			height={ props.height } 
		>
		</ResponsiveContainer>
	*/
	return (
		<AreaChart
			data={ props.chartData }
			margin={{ top: 0, right: 20, left: 0, bottom: 0 }}
			width={ props.width } 
			height={ props.height } 
		>
			<defs>
				<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
					<stop offset="5%" stopColor="rgb(125, 210, 247)" stopOpacity={ 0.4 }/>
					<stop offset="95%" stopColor="rgb(125, 210, 247)" stopOpacity={ 0 }/>
				</linearGradient>
			</defs>
			<CartesianGrid 
				stroke="#ccc"
				//strokeDasharray="2 2"
				//horizontalFill={['#fff', '#ddd']}
				//fillOpacity={0.4}
			/>
			<XAxis 
				dataKey="t" 
				type="number" 
				stroke="#003c4e" 
				allowDataOverflow={ false }
				xAxisId={ 0 } 
				tickFormatter={ props.formatXAxisTicks } 
	            ticks={ props.xAxisTicks }
				style={{ fontSize:'11px', fontWeight:'bold', color:'#003c4e' }} 
				domain={[ props.minTimestamp, props.maxTimestamp ]} 
			/>
			<YAxis 
				type="number" 
				stroke="#003c4e" 
				yAxisId={ 0 } 
				tickFormatter={ props.formatYAxisTicks } 
	            ticks={  props.yAxisTicks  }
				style={{ fontSize:'11px', fontWeight:'bold', color:'#003c4e' }} 
				domain={[ props.minQuotation, props.maxQuotation ]} 
			/>
			<Tooltip 
				content={ <RenderTooltip /> }
				cursor={{ stroke: '#003c4e', strokeWidth: 1 }}
			/>
			<Area 
				//type="monotone" 
				type="linear" 
				dataKey="EUR" 
				stroke="#003c4e" 
				fillOpacity={1} 
				fill="url(#colorUv)" 
				yAxisId={ 0 } 
			/>
		</AreaChart>
	)
}

export default Graph
