import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'
import ActionButton from '../ActionButton/ActionButton';

const Ahover = styled.div`
  a { text-decoration:none; color:black; display:inline-block; padding-bottom:0; color:#000; background-color:rgba(32, 190, 225, 0.1); padding:0px 0; }
  a:hover { color:#fff; background-color:rgb(32 190 225); }
`;

const Prospectus = props => {
  let title = props.page.title
  if ( props.title !== undefined ) {
    title = props.title
  }

  const [ accepted, setAccepted ] = useState( false );

  //console.log( 'Generic props.structure', props.structure )
  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor || '#fff', 
    color:props.structure.color || '#000', 
    padding:'30px 0 0 0', 
    boxSizing:'border-box', 
  }} >
    <div className="products" style={{ maxWidth:'900px' }}  >
      { title && 
      <h1 style={{ padding:'30px 30px 30px 30px', margin:'0', boxSizing:'border-box' }}>{ parseHtml( title  || '' ) }</h1>
      }

      { !accepted && 
      <Ahover style={{ padding:'10px 30px', margin:'0', fontSize:'15px', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column1 || '' ) }
      </Ahover>
      }

      { accepted && 
      <Ahover style={{ padding:'10px 30px', margin:'0', fontSize:'15px', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column2 || '' ) }
      </Ahover>
      }

      { !accepted && 
      <div style={{ margin:'20px 0 20px 20px' }} >

        <ActionButton onClick={ () => { console.log( 'set' ); setAccepted( true ); } } title={ props.structure.button1Text } style={{ cursor:'pointer', display:'inline-block', width:'115px', margin:'0 10px' }} />
        <Link to="/">
          <ActionButton title={ props.structure.button2Text } style={{ display:'inline-block', width:'110px', margin:'0 10px' }} />
        </Link>
      </div>
      }

    </div>
  </div>
  )
}
export default Prospectus
