const johdonLiiketoimet = `<h2>Privanet Group Oyj: vanhemmat johdon liiketoimet</h2>
<p></p>
<p>6.10.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet100620_Mininvest.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>6.10.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet100620_Growroad.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>29.5.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet290520.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>18.5.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet180520.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>15.5.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet150520.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>24.3.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet240320.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>19.3.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet190320.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>13.3.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet1303020.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>3.3.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon_liiketoimet030320.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>29.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Mininvest290120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>29.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Lindström290120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>29.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Mash290120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>28.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Mininvest280120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>28.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Lindström280120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>28.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Anti-Invest280120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>27.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Mininvest270120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>27.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Anti-Invest270120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>27.1.2020 -<a target="_blank" href="https://privanetgroup.fi/documents/Johdon-liiketoimet_Westerlund270120.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>26.8.2019 -<a target="_blank" href="https://privanetgroup.fi/documents/JohdonLiiketoimetMashServices.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>26.8.2019 -<a target="_blank" href="https://privanetgroup.fi/documents/JohdonLiiketoimetMininvest.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>25.9.2018 -<a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote25.9.-1.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>3.1.2018 -<a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_johdonliiketoimet_PAHoldings03012018.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>3.1.2018 -<a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_johdonliiketoimet_AavaCapital03012018.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>9.11.2017 -<a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_johdonliiketoimet_09112017.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>8.3.2017 -<a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_Johtohenkiloiden_liiketoimet080317.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>9.11.2016 -<a target="_blank" href="https://privanetgroup.fi/documents/JohdonLiiketoimet_Yhtiotiedote_FinactuOy081116.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>28.10.2016 -<a target="_blank" href="https://privanetgroup.fi/documents/JohdonLiiketoimet_Yhtiotiedote_FinactuOy281016.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>5.10.2016 -<a target="_blank" href="https://privanetgroup.fi/documents/JohdonLiiketoimet_Yhtiotiedote_Pettersson.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>4.10.2016 -<a target="_blank" href="https://privanetgroup.fi/documents/JohdonLiiketoimet_Yhtiotiedote_FinactuOy.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>3.8.2016 -<a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_Yhtiotiedote_JohdonLiiketoimet03082016_MMPetterssonOy.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
<p>3.8.2016 -<a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_Yhtiotiedote_JohdonLiiketoimet03082016_FinactuOy.pdf">Privanet Group Oyj: Johdon liiketoimet</a></p>
`

export default johdonLiiketoimet
