import React, { Fragment, useEffect } from 'react';
import parseHtml from '../parseHtml/parseHtml';
import getArticleAndImage from '../News/getArticleAndImage';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';
import Utils from '../Equities/Utils/Utils';

const NewsPage = props => {

	useEffect( () => {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}, [] )

	let backTo = '/news/'
	if ( props.location.state && props.location.state.backFromPageTo ) {
		backTo = props.location.state.backFromPageTo
	}

	const { newsArticle, imageUrl } = getArticleAndImage( props.match.params.slug )
    let copyHtml = parseHtml( newsArticle.copy || '' )

	return ( 
		<Fragment>

    		<BreadCrumbs 
    			to={ backTo }
    			history={ props.history }
    			img={ imageUrl } 
    			title="Uutiset"
    			titles={ [ 'News', newsArticle.title ] } 
			/>

	        <div className="products" >
    			<div style={{ background:'#fff', padding:'30px 30px 30px 30px' }} >
			    	<h1 style={{ lineHeight:'50px', margin:'20px 0 0 0', textAlign:'left' }} >{ newsArticle.title }</h1>
		    	</div>
	    	</div>

	        <div className="products" >
    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
	 		    	{ Utils.isoDateToFi( newsArticle.display_date ) || '' }
		    	</div>
	    	</div>
    		{/*
	        <div className="products" >
    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
	 		    	{ parse( newsArticle.lead || '' ) }
		    	</div>
	    	</div>
			*/}
	        <div className="products" >
    			<div className="news-page" style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
	 		    	{ copyHtml }
		    	</div>
	    	</div>

		</Fragment>
	)
}

export default NewsPage

