import React,{ Fragment } from 'react';
//import '../css/fonts.css';
//import '../css/commonStyles.css';
import GraphTabs from './GraphTabs';
import Graph from './Graph';

class GraphSet extends React.Component {

	constructor( props ) {
		super( props );
		this.state = {
			tmp:null,
			width:window.innerWidth > 1100 ? 1100 : window.innerWidth, 
			height:window.innerHeight,
			currentTabIndex:2,
		};

		this.updateWindowResize = this.updateWindowResize.bind( this )
		this.setCurrentTabIndex = this.setCurrentTabIndex.bind( this )
		this.formatXAxisTicks = this.formatXAxisTicks.bind( this )

		this.xAxisTicks = []
		this.yAxisTicks = []
		this.chartData = []

		let d = new Date();
		let d3kk = new Date( d.setMonth( d.getMonth() - 3 ) )
		let d6kk = new Date( d.setMonth( d.getMonth() - 3 ) )
		let d12kk = new Date( d.setMonth( d.getMonth() - 6 ) )

		this.graphTabsData = [
			{ title:'3kk', firstDate:d3kk },
			{ title:'6kk', firstDate:d6kk },
			{ title:'12kk', firstDate:d12kk },
			{ title:'Kaikki', firstDate:null },
		]

	}

	formatXAxisTicks( tickValue ) {
		let date = new Date( tickValue * 1000 );
		let year = date.getYear() + 1900;
		let month = date.getMonth() + 1
		let day = date.getDate();
		if ( this.state.currentTabIndex === 3 ) {
			return year
		} else if ( month === 1 ) {
			return month + '/' + year
		} else {
			return month
		}
	}

	formatYAxisTicks( tickValue ) {
		return Math.round( tickValue * 100 ) / 100
	}

  	updateWindowResize() {
  		let w = window.innerWidth
  		if ( w > 1100 ) {
  			w = 1100
  		}

	    this.setState({ width:w, height:window.innerHeight });
  	}

  	generateSeries( data, minDate, currentTabIndex ) {

		// process Data
		let minTimestamp = null
		if ( minDate !== null ) {
			minTimestamp = Math.floor( minDate.getTime() / 1000 )
		}

		// generate chartData and find limits
		this.chartData = []
		this.metaData = {}

		//let tmpChartData = []
		let limits = { 
			minTimestamp:null, 
			maxTimestamp:null, 
			minQuotation:null, 
			maxQuotation:null 
		}
		data.map( ( a, i ) => {
			let date = new Date( a[ 1 ] * 1000 )
			let t = Number( a[ 1 ] )
			if ( minTimestamp === null || t >= minTimestamp ) {
				let year = date.getYear() + 1900
				let month = date.getMonth() + 1
				let day = date.getDate()
				let quotation = Number( a[ 3 ] )
				if ( limits.minTimestamp === null || t < limits.minTimestamp ) {
					limits.minTimestamp = t
				}
				if ( limits.maxTimestamp === null || t > limits.maxTimestamp ) {
					limits.maxTimestamp = t
				}
				if ( limits.minQuotation === null || quotation < limits.minQuotation ) {
					limits.minQuotation = quotation
				}
				if ( limits.maxQuotation === null || quotation > limits.maxQuotation ) {
					limits.maxQuotation = quotation
				}
				this.chartData.push( { date:a[ 2 ], EUR:quotation, year:year, month:month, day:day, t:t, d:date } )
			}
		} )

		this.metaData = { 
			minTimestamp:limits.minTimestamp, 
			maxTimestamp:limits.maxTimestamp, 
			minQuotation:Math.round( limits.minQuotation * .7 * 100) / 100, 
			maxQuotation:Math.round( limits.maxQuotation * 1.3 * 100) / 100 
		}

		// yAxisTicks
		this.yAxisTicks = []
		let ySpan = Math.round( ( this.metaData.maxQuotation - this.metaData.minQuotation ) * 100 ) / 100
		let yDiv = Math.round( ( ySpan / 4 ) * 100 ) / 100

		// set yStep from prettyTicks
		const prettyTicks = [ 0.05, 0.1, 0.2, 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 250, 500, 1000, 1500, 2000, 2500, 5000, 7500, 10000, 15000, 20000, 50000, 100000 ]
		let yStep = prettyTicks[ 0 ]
		prettyTicks.map( a => {
			if ( a <= yDiv ) {
				yStep = a
			}
		} )

		// generate yAxisTicks
		let y = Math.floor( this.metaData.minQuotation / yStep ) * yStep
		while ( y < this.metaData.maxQuotation ) {
			this.yAxisTicks.push( Math.round( y * 100 ) / 100 )
			y = Math.round( ( y + yStep ) * 100 ) / 100
		}

		// xAxisTicks
		this.xAxisTicks = []
		let d1 = new Date( this.metaData.minTimestamp * 1000 )
		let d2 = new Date( this.metaData.maxTimestamp * 1000 )
		let m1 = d1.getMonth() + 1
		let m2 = d2.getMonth() + 1
		let y1 = d1.getYear() + 1900
		let y2 = d2.getYear() + 1900
		let year = y1
		let month = m1
		let t

		const incrementTime = ( timeUnit ) => {
			if ( timeUnit === 'year' ) {
				year = year + 1
				month = 1
			} else if ( timeUnit === 'month' ) {
				month = month + 1
				if ( month > 12 ) {
					year = year + 1
					month = 1
				}
			}
			t = Math.floor( new Date( year, month - 1, 1 ).getTime() / 1000 )
		}

		const generateTicks = ( timeUnit ) => {
			incrementTime( timeUnit )
			while ( t <= this.metaData.maxTimestamp ) {
				this.xAxisTicks.push( t )
				incrementTime( timeUnit )
			}
		}

		if ( currentTabIndex === 3 ) {
			generateTicks( 'year' )
		} else {
			generateTicks( 'month' )
		}

  		this.setState( { currentTabIndex:currentTabIndex } )

  	}


  	setCurrentTabIndex( currentTabIndex ) {
		let data = this.props.data 
		let minDate = this.graphTabsData[ currentTabIndex ].firstDate
		if ( data && data.all ) {
			this.generateSeries( data.all, minDate, currentTabIndex )
		}
  	}

	componentDidMount() {
	    window.addEventListener( 'resize', this.updateWindowResize )
		let minDate = this.graphTabsData[ this.state.currentTabIndex ].firstDate
		if ( this.props.data && this.props.data.all ) {
			this.generateSeries( this.props.data.all, minDate, 2 )
		}
	}

  	componentWillUnmount() {
    	window.removeEventListener( 'resize', this.updateWindowResize );
  	}


	render( props ) {

		return (
			<Fragment>
				<h3 style={{ fontWeight:'700' }} >Kurssikehitys</h3>
				<div style={{ margin:'17px 40px 7px 0', textAlign:'right' }} >
					<GraphTabs
						data={ this.graphTabsData }
						setCurrentTabIndex={ this.setCurrentTabIndex }
						currentTabIndex = { this.state.currentTabIndex }
					/>											
				</div>
				<Graph 
					width={ this.state.width - ( window.innerWidth >= 800 ? 390 : 10 ) }
					height={ 317 }
					chartData={ this.chartData }
					xAxisTicks={ this.xAxisTicks }
					formatXAxisTicks={ this.formatXAxisTicks }
					yAxisTicks={ this.yAxisTicks }
					formatYAxisTicks={ this.formatYAxisTicks }
				/>
			</Fragment>
		)
	}
}

export default GraphSet

