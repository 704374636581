import React,{ Fragment } from 'react';

const BreadCrumbs = props => (
	<div 
		className="banner-parent" 
		style={{ position:'relative', cursor:'pointer' }} 
		onClick={ () => { props.to === 'back' ? props.history.goBack() : props.history.push( props.to ) } } 
	>
		<div className="banner-child" >
			<img className="banner-img" alt="" src={ props.img } />
		</div>

		<div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'100%', top:0, left:'50%', transform:'translateX(-50%)', boxSizing:'border-box' }} >
			<div style={{ position:'absolute', left:0, bottom:0, textAlign:'left', padding:'0 0 0 20px', color:'white', height:'70px', overflow:'hidden', width:'100%', fontWeight:'bold' }} >
				<div style={{ display:'block', position:'absolute', left:'30px', bottom:'30px', height:'30px', padding:'4px 8px', borderRadius:'20.5px', fontSize:'1rem', background:'rgba(0,0,0,0.15)', textShadow:'0 1px 2px rgba(0,0,0,0.5)' }} >
					<span className="material-icons" style={{ transform:'translateY(3px)' }} >arrow_back</span>
					<div style={{ display:'inline-block', transform:'translateY(-3px)', padding:'0 10px' }} >Palaa</div>
				</div>
			</div>
		</div>
	</div>
)

export default BreadCrumbs
