import React,{ Fragment } from 'react';
import { MainTable } from './Ui/Ui';

const OrdersTable = props => {
	if ( props.ordersArray ) {
	return (
		<Fragment>
		<h3 style={{ fontWeight:'700', marginBottom:'10px' }} >Tarjoustasot</h3>
		<MainTable padding="0 0 0 20px" tableStyle={{ width:'100%', maxWidth:'340px' }} >
			<tr className="row" style={{ borderBottom:'1px solid #aaa' }} >
				<td className="col upper" style={{ minWidth:'80px', padding:"15px 10px 5px 10px", fontWeight:'bold', fontSize:'14px', verticalAlign:'bottom'  }} >MÄÄRÄ</td>
				<td className="col" style={{ padding:"15px 10px 5px 10px", fontWeight:'bold', color:'green', fontSize:'14px' }} >OSTO</td>
				<td className="col upper" style={{ padding:"15px 10px 5px 10px", fontWeight:'bold', textAlign:'right', color:'red', fontSize:'14px', verticalAlign:'bottom'  }} >MYYNTI</td>
				<td className="col" style={{ padding:"15px 10px 5px 10px", fontWeight:'bold', textAlign:'right', fontSize:'14px' }} >MÄÄRÄ</td>
			</tr>
			{ props.ordersArray.map( ( c, i ) => ( 
				<tr key={ i } className="row" style={{ borderBottom:'1px solid #aaa', backgroundColor:( i % 2 === 0 ? '#fff' : '#fff' ) }} >
					<td className="col upper" style={{ padding:"5px 10px", height:'38px', textAlign:'left', fontSize:'14px', verticalAlign:'bottom'  }} >{ c.buy_amount }</td>
					<td className="col" style={{ padding:"5px 10px", textAlign:'left', fontSize:'14px' }} >{ c.buy_price }</td>
					<td className="col" style={{ padding:"5px 10px", textAlign:'right', fontSize:'14px' }} >{ c.sell_price }</td>
					<td className="col upper" style={{ padding:"5px 10px", textAlign:'right', fontSize:'14px', verticalAlign:'bottom'  }} >{ c.sell_amount }</td>
				</tr>
			) ) }
		</MainTable>
		</Fragment>
	) } else {
		return null
	}
}

export default OrdersTable
