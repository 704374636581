import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'; 
import styled from 'styled-components'
import bgImage from '../img/logo-black.png';
import bgImageWhite from '../img/logo-white.png';
import ActionButton from '../ActionButton/ActionButton';

//import '../css/index.css';

const ShowOnlyOnDesktop = styled.div`
  display: none;
  @media only screen and ( min-width: 1037px) {
    display: block;
  }
`;

const ShowOnlyOnMobile = styled.div`
  display: none;
  @media only screen and ( max-width: 1036px) {
    display: block;
  }
`;

const SectionsDesktop = props => {

  let color = props.top ? 'white' : 'black' 
  let underLine = props.top ? '32,190,225' : '32,190,225' 

  return (
    <>
    <div style={{ display:'flex', userSelect:'none', margin:'22px 0 0 0' }} >
      {/*
      <div style={{ padding:'0 0 4px 0', margin:'0 5px', borderBottom:'4px solid rgba('+underLine+',' + ( props.route === 'stock' ? '1' : '0' ) + ')' }} >
        <Link draggable="false" to="/stock/" style={{ color:color }} >
          NOTEERAUKSET
        </Link>
      </div>

      <div style={{ padding:'0 0 4px 0', margin:'0 5px', borderBottom:'4px solid rgba('+underLine+',' + ( props.route === 'news' ? '1' : '0' ) + ')' }} >
        <Link draggable="false" to="/news/" style={{ color:color }} >
            UUTISET
        </Link>
      </div>

      <div style={{ padding:'0 0 4px 0', margin:'0 5px', borderBottom:'4px solid rgba('+underLine+',' + ( props.route === 'services' ? '1' : '0' ) + ')' }} >
        <Link draggable="false" to="/services/" style={{ color:color }} >
            ASIOINTI MEILLÄ
        </Link>
      </div>
      */}
      <div style={{ padding:'0 0 4px 0', margin:'0 5px', borderBottom:'4px solid rgba('+underLine+',' + ( props.route === 'privanet-group' ? '1' : '0' ) + ')' }} >
        <Link draggable="false" to="/privanet-group/" style={{ color:color }} >
            PRIVANET GROUP
        </Link>
      </div>

      <div style={{ padding:'0 0 4px 0', margin:'0 5px', borderBottom:'4px solid rgba('+underLine+',' + ( props.route === 'contact-us' ? '1' : '0' ) + ')' }} >
        <Link draggable="false" to="/contact-us/" style={{ color:color }} >
            YHTEYSTIEDOT
        </Link>
      </div>

      <div style={{ padding:'0 0 4px 0', margin:'0 5px', borderBottom:'4px solid rgba('+underLine+',' + ( props.route === 'share-exchange' ? '1' : '0' ) + ')' }} >
        <Link draggable="false" to="/share-exchange/" style={{ color:color }} >
            OSAKEVAIHTO
        </Link>
      </div>




    </div>
    </>
  )  
}

const SignInSignUp = props => {
  return (
    <div style={{ display:'flex', whiteSpace:'nowrap' }} >

      <div style={{ margin:'0 auto' }} >
        <a 
          href="https://privanet.fi/premarket/" 
          target="_blank" 
          style={{ 
            display:'inline-block', 
            transform:'translateY(-12px)',
            padding:'12px 18px',
            fontSize:'0.75rem',
            color:props.color 
          }} 
        >
            KIRJAUDU
        </a>
      </div>

      <div style={{ transform:'translateY(-20px)' }} >
        <a href="https://privanet.fi/signup/" target="_blank" >
          <ActionButton title="REKISTERÖIDY" style={{ width:'150px' }} />
        </a>
      </div>


    </div>
  )
}

const Header = props => {

  let route = props && props.match && props.match.params ? props.match.params.route : null

  const [ menu, setMenu ] = useState( false )

  useEffect( () => {
  }, [] )

  let top = props.offset < 100 
  let color = top ? 'white' : 'black' 
  //let underLine = top ? '32,190,225' : '32,190,225' 
  let bgUrl = top ? `${ bgImageWhite }` : `${ bgImage }`

  return (
    <Fragment>
    <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'60px', boxSizing:'border-box' }} >
      {/*
      <div style={{ width:'100%', backgroundColor:'yellow' }} >
      <div className="products" style={{ padding:'8px 35px', fontSize:'12px', fontWeight:'700', textAlign:'center' }} >
          Privanet Group oyj on nyt Skarta Group Oyj, eikä tätä sivustoa enää päivitetä. lue ajantasaiset yhtiötä koskevat tiedot osoitteesta www.skartagroup.fi.
      </div>
      </div>
      */}

      <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'60px', boxSizing:'border-box', boxShadow:!top && '0 2px 16px rgba(0,0,0,0.25)', background:!top ? 'white' : 'none' }} />
        <div className="products" style={{ position:'relative', width:'100%', textAlign:'left', fontSize:'0.75rem', lineHeight:'14px', fontWeight:'700', whiteSpace:'nowrap', userSelect:'none' }} >

          <div style={{ position:'absolute', top:0, left:0, margin:'10px 0 0 30px'}} >
            <Link draggable="false" to="/privanet-group/" >
              <img style={{ display:'block', width:'150px', transform:'translate(0,0)' }} draggable="false" alt="Logo" src={ bgUrl }/>
            </Link>
          </div>

          <ShowOnlyOnDesktop style={{ position:'absolute', left:'50%', top:0, transform:'translateX(-50%)', margin:'0 100px 0 0' }} >
            <SectionsDesktop top={ top } route={ route } />
          </ShowOnlyOnDesktop>

          {/*
          <ShowOnlyOnDesktop style={{ position:'absolute', top:0, right:0, whiteSpace:'nowrap', margin:'22px 30px 0 0' }} >
            <SignInSignUp top={ top } color={ color } />
          </ShowOnlyOnDesktop>
          */}

        <ShowOnlyOnMobile>
        { menu 
          ? <div 
              style={{ position:'fixed', cursor:'pointer', left:0, top:0, width:'100%', height:'100%', textAlign:'right', color:'black', backgroundColor:'rgba(0,0,0,0.25)' }} 
              onClick={ () => setMenu( false ) }
            >
              <div style={{ position:'absolute', right:'-5px', top:'-5px', margin:0, width:'240px', height:'400px', borderRadius:'8px', background:'white', color:'#000', boxShadow:'0 0 8px 1px rgba(0,0,0,0.25)',  }} >
                <div 
                  style={{ opacity:'100%', userSelect:'none', position:'absolute', right:'30px', top:'25px' }} 
                >
                  <span className="material-icons">close</span>
                </div>

                <div 
                  style={{ opacity:'100%', cursor:'pointer', userSelect:'none', position:'absolute', right:'34px', top:'60px', fontSize:'1rem', lineHeight:'40px', fontWeight:'700' }} 
                >
                  <div style={{ padding:'5px' }} />
                  {/*
                  <Link draggable="false" to="/stock/" >
                    <div>NOTEERAUKSET</div>
                  </Link>
                  <Link draggable="false" to="/news/" >
                    <div>UUTISET</div>
                  </Link>
                  <Link draggable="false" to="/services/" >
                    <div>ASIOINTI MEILLÄ</div>
                  </Link>
                  */}
                  <Link draggable="false" to="/privanet-group/" >
                    <div>PRIVANET GROUP</div>
                  </Link>
                  <Link draggable="false" to="/contact-us/" >
                    <div>YHTEYSTIEDOT</div>
                  </Link>
                  <Link draggable="false" to="/share-exchange/" >
                    <div>OSAKEVAIHTO</div>
                  </Link>




                  {/*
                  <div style={{ padding:'8px' }} />
                  <a href="https://privanet.fi/premarket/" target="_blank" style={{ color:'black' }} >
                    <div>KIRJAUDU</div>
                  </a>
                  <a href="https://privanet.fi/signup/" target="_blank" style={{ color:'black' }} >
                    <ActionButton title="REKISTERÖIDY" style={{ display:'inline-block', margin:'14px -14px 0 20px' }} />
                  </a>
                  */}
                </div>

              </div>
            </div>
          : <Fragment>
              <div 
                style={{ opacity:'100%', cursor:'pointer', userSelect:'none', position:'absolute', color:color, right:'25px', top:'20px' }} 
                onClick={ () => setMenu( true ) }
              >
                <span className="material-icons">menu</span>
              </div>
            </Fragment>
        }
        </ShowOnlyOnMobile>

      </div>
    </div>
    </Fragment>
  )  
}

export default Header
