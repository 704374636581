import React from 'react';
import Equities from '../Equities/Equities';
import Security from '../Equities/Security';
import News from '../LongScroll/News';
import NewsBannerOneColumn from '../LongScroll/NewsBannerOneColumn';
import NewsBannerTwoColumns from '../LongScroll/NewsBannerTwoColumns';
import NewsBannerLeft from '../LongScroll/NewsBannerLeft';
import NewsBannerRight from '../LongScroll/NewsBannerRight';

import MainEquities from '../LongScroll/MainEquities';

import Employees from '../LongScroll/Employees';
import Blogs from '../LongScroll/Blogs';
import Register from '../Register/Register';
import HeroFront from '../LongScroll/HeroFront';
import Hero from '../LongScroll/Hero';
import HeroTabs from '../LongScroll/HeroTabs';
import Generic from '../LongScroll/Generic';
import Prospectus from '../LongScroll/Prospectus';
import Stt from '../LongScroll/Stt';
import TwoColumns from '../LongScroll/TwoColumns';
import ThreeColumns from '../LongScroll/ThreeColumns';
import FourColumns from '../LongScroll/FourColumns';

const Block = props => {
  let structure = JSON.parse( props.page.structure )
  //console.log( 'sub', props.sub, props.slug )
  //return (
    if ( props.page.template === 'hero-front' ) {
      return (
        <HeroFront key={ props.page.id } section={ props.section } page={ props.page } structure={ structure } files={ props.page.files } />
      )
    } else if ( props.page.template === 'hero' ) {
      return (
        <Hero key={ props.page.id } back={ props.sub } history={ props.history } slug={ props.slug } sub={ props.sub } section={ props.section } page={ props.page } structure={ structure } files={ props.page.files } />
      )
    } else if ( props.page.template === 'hero-tabs' ) {
      return (
        <HeroTabs key={ props.page.id } back={ props.sub } history={ props.history } slug={ props.slug } sub={ props.sub } section={ props.section } page={ props.page } structure={ structure } files={ props.page.files } />
      )
    } else if ( props.page.template === 'three-columns' ) {
      return (
        <ThreeColumns key={ props.page.id } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'generic' ) {
      return (
        <Generic key={ props.page.id } title={ props.title } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'prospectus' ) {
      return (
        <Prospectus key={ props.page.id } title={ props.title } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'stt' ) {
      return (
        <Stt key={ props.page.id } title={ props.title } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'two-columns' ) {
      return (
        <TwoColumns key={ props.page.id } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'four-columns' ) {
      return (
        <FourColumns key={ props.page.id } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'main-equities' && !props.sub ) {
      return (
        <MainEquities history={ props.history } slug={ props.slug } sub={ props.sub } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'equities' && !props.sub ) {
      return (
        <Equities key={ props.page.id } history={ props.history } slug={ props.slug } sub={ props.sub } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'equities' && props.sub ) {
      return (
        <Security key={ props.page.id } history={ props.history } slug={ props.slug } sub={ props.sub } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'register' ) {
      return (
        <Register key={ props.page.id } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'news' ) {
      return (
        <News key={ props.page.id } page={ props.page } limit={ props.newslimit } structure={ structure } />
      )
    } else if ( props.page.template === 'news-banner-1-column' ) {
      return (
        <NewsBannerOneColumn key={ props.page.id } page={ props.page } limit={ props.newslimit } structure={ structure } />
      )
    } else if ( props.page.template === 'news-banner-2-columns' ) {
      return (
        <NewsBannerTwoColumns key={ props.page.id } page={ props.page } limit={ props.newslimit } structure={ structure } />
      )
    } else if ( props.page.template === 'news-banner-left' ) {
      return (
        <NewsBannerLeft key={ props.page.id } page={ props.page } limit={ props.newslimit } structure={ structure } />
      )
    } else if ( props.page.template === 'news-banner-right' ) {
      return (
        <NewsBannerRight key={ props.page.id } page={ props.page } limit={ props.newslimit } structure={ structure } />
      )
    } else if ( props.page.template === 'employees' ) {
      return (
        <Employees key={ props.page.id } page={ props.page } structure={ structure } />
      )
    } else if ( props.page.template === 'blog' ) {
      return (
        <Blogs key={ props.page.id } page={ props.page } structure={ structure } />
      )
    } else {
      return (
        <Generic key={ props.page.id } page={ props.page } structure={ structure } />
      )
    }
  //)
}

export default Block
