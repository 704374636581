import React from 'react';
import { Link } from 'react-router-dom'; 
import icons from '../icons/icons';
import parse from 'html-react-parser';

const HeroIcon = props => (
  <Link to={ props.to } style={{ color:'white', display:'block', width:'25%', height:'60px', padding:'10px', textAlign:'center', borderRight:props.border ? '1px solid rgba(255,255,255,0.4)' : null }} >
      <span style={{ opacity:'0.7' }} >
        { parse( icons[ props.icon ] ) }
      </span>
      <br/><br/>
        { parse( props.title ) }
  </Link>
)

const HeroIconRow = props => (
  <div style={{ 
    display:'flex', width:'100%', maxWidth:'600px', padding:'20px 0', margin:'20px auto 0 auto',
    fontSize:'8px', lineHeight:'10px', fontWeight:'700', textAlign:'center', color:'white', textTransform:'uppercase',
    background:'rgba(13,116,147,0.7)', mixBlendMode:'normal', boxSizing:'border-box', 
    boxShadow:'0px 0px 15px rgba(0, 0, 0, 0.693865)', border:'1px solid rgba(255, 255, 255, 0.396285)', 
  }}>
    <HeroIcon to="/services/account" icon="asiakkuuden_avaaminen" title="Asiakkuuden<br>avaaminen" border={ true } />
    <HeroIcon to="/services/order" icon="yksittainen_toimeksianto" title="yksittäinen<br>toimeksianto" border={ true } />
    <HeroIcon to="/services/estate" icon="kuolinpesan_toimeksianto" title="kuolinpesän<br>toimeksianto" border={ true } />
    <HeroIcon to="/services/fees" icon="hinnasto" title="hinnasto<br>&nbsp;" border={ false } />
  </div>
)

const FrontTitle = props => (
  <div style={{ 
    userSelect:'none', display:'block', 
    textAlign:'center', padding:'90px 0 0 0', margin:'auto', boxSizing:'border-box', 
    fontFamily:'futuraheavy', textTransform:'uppercase', 
    fontSize:'2.5rem', fontWeight:'700', lineHeight:'2.5rem', letterSpacing:'2.5px', 
    textShadow:'0 2px 4px rgba(0,0,0,1)', color:'white', 
  }}>
    { props.title }
  </div>
)

const HeroFront = props => (
  <div id="frontpage" className="front-wrapper" >
    <img 
      style={{ position:'absolute', top:0, left:0, width:'100%', height:'72%', minHeight:'300px', objectFit:'cover', objectPosition:'50% 0', opacity:1 }} alt="" 
      src={ props.files[ 0 ] } 
    />
    <div className="front-shadow" style={{ height:'72%', minHeight:'300px', background:'linear-gradient(180deg, rgba(21, 45, 85, 0.735905), rgba(47, 134, 202, 0.0001), rgba(21, 45, 85, 0.735905) )' }} />
    <div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'70%', minHeight:'300px', top:0, left:'50%', transform:'translateX(-50%)', boxSizing:'border-box' }} >
      <div className="front" style={{ height:'100%', display:'flex', flexDirection:'column', justifyContent:'center' }} >
        <FrontTitle title={ props.structure.titleTextFi } />
        <HeroIconRow/>
      </div>
    </div>
  </div>
)

export default HeroFront
