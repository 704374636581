import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../ActionButton/ActionButton';

const NewsCard = props => (
	<Link 
		draggable="false" 
		to={{
		  pathname:'/news/' + props.slug + '/',
		  state: {
		    backFromPageTo:props.backFromPageTo
		  }
		}} 
		className="blogs-list-block"
	>
		<table className="card-hover" >
			<tbody>
				<tr><td colSpan="2" style={{ width:'100%', padding:'0 10px 0 10px', margin:'0', boxSizing:'border-box' }} >
					<img 
						src={ props.row.news_img || props.row.company_img } 
						draggable="false" 
						alt="" 
						style={{ 
							objectFit:'cover', margin:'0', width:'100%', height:'240px', 
							//borderBottom:'1px solid #ccc',
							boxSizing:'border-box',  
						}} 
					/>
				</td></tr>
				{/*
				<tr><td colSpan="2" style={{ padding:'0 10px 2px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
			    	<div style={{ padding:'0', margin:'0', transform:'translateY(-3px)', color:'#999', fontSize:'12px', lineHeight:'18px', height:'18px', overflow:'hidden', boxSizing:'border-box' }} >
			    		{ props.row.display_date }
			    	</div>
				</td></tr>
				*/}
				<tr><td colSpan="2" style={{ padding:'10px 10px 0 10px', verticalAlign:'top', boxSizing:'border-box' }} >
			    	<div style={{ padding:'0', margin:'0', fontSize:'1rem', lineHeight:'1.5rem', fontWeight:'700', textAlign:'center', textTransform:'uppercase', height:'102px', overflow:'hidden', boxSizing:'border-box' }} >
			    		{ props.row.title }
			    	</div>
				</td></tr>
				<tr><td colSpan="2" style={{ padding:'0 10px 8px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
			    	<div style={{ padding:'0', margin:'0', fontSize:'0.9rem', lineHeight:'1.25rem', textAlign:'center', height:'160px', overflow:'hidden', boxSizing:'border-box' }} >
			    		{ props.row.lead }
			    	</div>
				</td></tr>

				<tr><td colSpan="2" style={{ padding:'0 10px 8px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
			    	<div style={{ padding:'0', margin:'0', textAlign:'center', height:'60px', overflow:'hidden', boxSizing:'border-box' }} >
						<ActionButton title="LUE LISÄÄ" style={{ display:'inline-block', width:'120px' }} />
			    	</div>
				</td></tr>


			</tbody>
		</table>
	</Link>
)

export default NewsCard
