import React, { Fragment, useState, useEffect } from 'react';
import IframeResizer from 'iframe-resizer-react'
import { Link } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import ActionButton from '../ActionButton/ActionButton';
import yhtiotiedotteet from '../Data/yhtiotiedotteet';
import johdonLiiketoimet from '../Data/johdonLiiketoimet';

const Stt = props => {
  let title = props.page.title
  if ( props.title !== undefined ) {
    title = props.title
  }

  const [ tiedotteet, setTiedotteet ] = useState( false )
  const [ liiketoimet, setLiiketoimet ] = useState( false )

  /*
  const [ url, setUrl ] = useState( null )
  useEffect( () => {
    const code = '<head></head><body><div id="embedded-pressroom-announcements" data-publisher="69817387"></div><div><a href="https://privanet.fi/news/privanet-group-vanhemmat-yhtiotiedotteet" target="_blank" style="display:block;color:black;font-family:sans-serif;padding-bottom:10px;" >Vanhemmat yhtiötiedotteet</a></div><div><a href="https://privanet.fi/news/privanet-group-vanhemmat-johdon-liiketoimet" target="_blank" style="display:block;color:black;font-family:sans-serif;padding-bottom:10px;" >Vanhemmat johdon liiketoimet</a></div><script type="text/javascript" src="https://www.sttinfo.fi/embedded/prs_embedded.js"></script></body>'
    const type = "text/html"
    const blob = new Blob( [ code ], { type } )
    setUrl ( URL.createObjectURL( blob ) )
  }, [ ] )
  */

  // resize
  // https://github.com/davidjbradshaw/iframe-resizer-react

  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor, 
    color:props.structure.color, 
    padding:'30px 0 0 0', 
    boxSizing:'border-box', 
  }} >
    <div className="products"  >
      { title && 
      <h1 style={{ padding:'0px 30px 20px 30px', margin:'0', boxSizing:'border-box' }}>{ title }</h1>
      }
      <div style={{ padding:'10px 20px', margin:'0', fontSize:'15px', boxSizing:'border-box' }}>
        {/*
        <iframe src={ url } width="100%" height="6000px" style={{ border:'none' }}  />
        */}
        <IframeResizer
          log
          src={ process.env.PUBLIC_URL + '/stt.html' }
          style={{ width: '1px', minWidth: '100%', border:'none' }}
        />

      </div>

      <div style={{ textAlign:'center', marginBottom:'40px' }} >

        { tiedotteet 
          ?
          <div style={{ textAlign:'left', padding:'0 30px' }} >
            <h2>Privanet Group Oyj: vanhemmat yhtiötiedotteet</h2>
            { parseHtml( yhtiotiedotteet ) }
          </div>
          :
          <div
            onClick={ () => { setTiedotteet( true ) } }
            style={{ display:'block', margin:'0 auto', color:'black', fontFamily:'sans-serif', paddingBottom:'10px' }} 
          >
            <ActionButton title="Vanhemmat yhtiötiedotteet" style={{ display:'inline-block', whiteSpace:'nowrap', width:'260px', background:'linear-gradient(180deg, #6b6b72 0%, #446 100%)' }} />
          </div>
        }

        { liiketoimet 
          ?
          <div style={{ textAlign:'left', padding:'0 30px' }} >
            <h2>Privanet Group Oyj: vanhemmat johdon liiketoimet</h2>
            { parseHtml( johdonLiiketoimet ) }
          </div>
          :
          <div
            onClick={ () => { setLiiketoimet( true ) } }
            style={{ display:'block', margin:'0 auto', color:'black', fontFamily:'sans-serif', paddingBottom:'10px' }} 
          >
            <ActionButton title="Vanhemmat johdon liiketoimet" style={{ display:'inline-block', whiteSpace:'nowrap', width:'260px', background:'linear-gradient(180deg, #6b6b72 0%, #446 100%)' }} />
          </div>
        }



      </div>

    </div>
  </div>
  )
}
export default Stt
