const yhtiotiedotteet = `<h2>Privanet Group Oyj: vanhemmat yhtiötiedotteet</h2>
<p></p>
<p>31.7.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote310720.pdf">Privanet Capital Markets Oy valitti Finanssivalvonnan päätöksestä</a></p>
<p>3.7.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote030720.pdf">Privanet Group Oyj:n uusi johtoryhmä on aloittanut tehtävissään</a></p>
<p>2.7.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote020720.pdf">Finanssivalvonta on määrännyt Privanet Capital Markets Oy:lle seuraamusmaksun ja julkisen varoituksen vuosina 2015-2017 harjoitetusta toiminnasta</a></p>
<p>14.5.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Täydennys-yhtiötiedotteeseen-140520-1.pdf">Privanet Group Oyj: Täydennys 14.5.2020 julkaistuun yhtiötiedotteeseen</a></p>
<p>14.5.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote_140520.pdf">Privanet hankkii Northern Star Partners Oy:n osakekannan ja laajentaa toimintaansa varainhoitopalveluihin</a></p>
<p>3.4.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote030420.pdf">Privanet Group Oyj: Varsinaisen yhtiökokouksen päätökset ja hallituksen järjestäytyminen</a></p>
<p>27.3.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote270320.pdf">Muutos Privanet Group Oyj:n yhtiökokouskutsuun</a></p>
<p>16.3.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote160320.pdf">Privanet Group Oyj: Noweco-rahaston markkinointi voidaan aloittaa</a></p>
<p>13.3.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiökokouskutsu2020.pdf">Privanet Group Oyj: Kutsu varsinaiseen yhtiökokoukseen</a></p>
<p>10.3.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote100320.pdf">Privanet Group Oyj:n vuosikertomus 2019 on julkaistu</a></p>
<p>2.3.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote020320.pdf">Privanet Group Oyj: Kaupankäynti Nordic SME -markkinapaikalla päättyy 13.3.2020</a></p>
<p>28.2.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote280220.pdf">Privanet Group Oyj laskee liikkeelle joukkovelkakirja- ja hybridilainan</a></p>
<p>28.2.2020 - L<a target="_blank" href="https://privanetgroup.fi/documents/Liite-tilinpäätöstiedotteeseen280220.pdf">iite Privanet Group Oyj:n tilinpäätöstiedotteeseen 2019</a></p>
<p>28.2.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Tilinpäätöstiedote280220.pdf">Privanet Group Oyj:n tilinpäätöstiedote vuodelta 2019 – suurten muutosten vuosi</a></p>
<p>3.2.2020 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote0302220.pdf">Privanet Group Oyj: NGM myynyt omistusosuutensa ja osakassopimus päättynyt</a></p>
<p>19.12.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote-191219.pdf">Privanet antaa tulosvaroituksen</a></p>
<p>27.11.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/YhtiökokousPäätökset271119.pdf">Privanet Group Oyj:n ylimääräisen yhtiökokouksen päätökset</a></p>
<p>6.11.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiökokouskutsu061119.pdf">Kutsu ylimääräiseen yhtiökokoukseen</a></p>
<p>4.10.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote041019.pdf">Riku Lindström Privanet Group Oyj:n toimitusjohtajaksi</a></p>
<p>1.10.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote011019.pdf">Mikael Westerlund Privanet Group Oyj:n talousjohtajaksi</a></p>
<p>3.9.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote030919.pdf">Johanna Hurskainen jättää Privanet Groupin talousjohtajan paikan</a></p>
<p>21.8.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Puolivuosikatsausliite-2019.pdf">Liite Privanet Group Oyj:n puolivuosikatsaukseen</a></p>
<p>21.8.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Puolivuosikatsaus-2019.pdf">PRIVANET GROUP OYJ:N PUOLIVUOSIKATSAUS 1.1.–30.6.2019: Oma pääoma vahvistunut ja liiketoiminnan kannattavuus parantunut merkittävästi toisen kvartaalin aikana</a></p>
<p>19.7.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote190719.pdf">Privanet tiivistää organisaatiotaan</a></p>
<p>19.6.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote170619_final.pdf">Privanet Groupin suurimmaksi osakkeenomistajaksi Mininvest Oy</a></p>
<p>14.6.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote140619.pdf">Privanet Group Oyj:n ylimääräisen yhtiökokouksen päätökset</a></p>
<p>12.6.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Priva-Yhtiötiedote-120619_final.pdf">Privanet Group Oyj suunnittelee osakeannin suuntaamista Mininvest Oy:lle</a></p>
<p>28.5.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote_28052019.pdf">Korjaus Privanet Group Oyj:n yhtiökokouskutsuun</a></p>
<p>24.5.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote_24052019.pdf">Privanet Group Oyj: Kutsu ylimääräiseen yhtiökokoukseen</a></p>
<p>9.5.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote090519.pdf">Privanet Group Oyj:n osakemäärä on noussut 15 454 317 osakkeeseen</a></p>
<p>25.4.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote250419.pdf">St1:n omistajiin kuuluva Kim Wiio nousee Privanetin suuromistajaksi</a></p>
<p>16.4.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote160419.pdf">Privanet Group Oyj laskee liikkeeseen kaksi viiden miljoonan euron joukkovelkakirjalainaa</a></p>
<p>27.3.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/YhtiötiedoteHallituksenJärjestäytyminen.pdf">Privanet Group Oyj:n hallituksen järjestäytyminen</a></p>
<p>27.3.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote270319-1.pdf">Privanet Group Oyj:n varsinaisen yhtiökokouksen päätökset</a></p>
<p>6.3.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiökokouskutsu2019.pdf">Privanet Group Oyj: Kutsu varsinaiseen yhtiökokoukseen</a></p>
<p>6.3.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote-Tilinpäätös.pdf">Privanet Group Oyj:n vuosikertomus 2018 on julkaistu</a></p>
<p>28.2.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/YhtiötiedoteNoweco.pdf">Privanet Groupin Noweco-rahastoyhtiökauppa saatu päätökseen</a></p>
<p>28.2.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Liite-tilinpäätöstiedotteeseen.pdf">Liite Privanet Group Oyj:n tilinpäätöstiedotteeseen 2018</a></p>
<p>28.2.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Tilinpäätöstiedote.pdf">Privanet Group Oyj:n tilinpäätöstiedote vuodelta 2018 – vaikea vuosi takana</a></p>
<p>7.2.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote070219.pdf">Privanet Group ostaa Noweco-pääomasijoitusyhtiöt ja perustaa listaamattomiin kasvuyrityksiin sijoittavan rahaston</a></p>
<p>6.2.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote060219.pdf">Privanet Group Oyj listautuu Nordic Growth Market AB:n (NGM) ylläpitämälle MTF-markkinapaikalle</a></p>
<p>1.2.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote010219.pdf">Privanet julkaisee tilinpäätöstiedotteen 28.2.2019 klo 9.00</a></p>
<p>29.1.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote290119.pdf">Privanetin yhteistoimintaneuvottelut saatu päätökseen</a></p>
<p>7.1.2019 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote070119.pdf">Privanet antaa tulosvaroituksen ja aloittaa yhteistoimintaneuvottelut</a></p>
<p>11.12.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote111218.pdf">Roy Harju eroaa Privanet Groupin hallituksesta – Timo T. Laitisesta hallituksen puheenjohtaja</a></p>
<p>30.10.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote3010-1.pdf">Kimmo Lönnmark Privanet Capital Markets Oy:n vt. toimitusjohtajaksi 1.1.2019 alkaen</a></p>
<p>29.10.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote2910.pdf">Privanet Capital Markets Oy on saanut tiedoksi vahingonkorvauskanteita sijoittajilta</a></p>
<p>24.10.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote2410.pdf">Privanetin Norjan-sivuliikkeen toiminta käynnistyy</a></p>
<p>31.8.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiötiedote3108.pdf">Privanet Securities Oy:n sivuliikkeen perustaminen Norjaan etenee suunnitellusti – Norjan valvova viranomainen antanut ilmoituksen toiminnan aloittamisesta</a></p>
<p>29.8.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Puolivuosikatsaustiedote_2908.pdf">Privanet Group Oyj:n puolivuosikatsaus 1.1.-30.6.2018</a></p>
<p>16.8.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_16082018.pdf">Privanet varoittaa koko vuoden tuloksesta</a></p>
<p>15.6.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_15062018.pdf">Raimo Vainionpää Privanet Capital Markets Oy:n toimitusjohtajan sijaiseksi</a></p>
<p>2.5.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_02052018.pdf">Camilla Andersson perustamaan Privanetin Norjan yksikköä</a></p>
<p>24.4.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_rahoitus_24042018.pdf">Privanet Group Oyj käynnisti listaamattomien yritysten kansainvälisen markkina-alustan kehityksen</a></p>
<p>24.4.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_24042018.pdf">Sami Järvinen jättää Privanet Group Oyj:n strategiajohtajan paikan</a></p>
<p>22.3.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_22032018.pdf">Privanet Group Oyj:n varsinaisen yhtiökokouksen päätökset 22.3.2018</a></p>
<p>1.3.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PG_kutsu_varsinainen_yhtiokokous22032018.pdf">Kutsu Privanet Group Oyj:n varsinaiseen yhtiökokoukseen</a></p>
<p>28.2.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_28022018.pdf">Privanet Group Oyj jättää hakemuksen rinnakkaislistautumisesta Nordic Growth Market AB:n (NGM) ylläpitämälle MTF-markkinapaikalle</a></p>
<p>28.2.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Liite_tilinpaatos2017_28022018.pdf">Liite Privanet Group Oyj:n tilinpäätöstiedotteeseen 2017</a></p>
<p>28.2.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_28022018.pdf">Privanet Group Oyj:n tilinpäätöstiedote vuodelta 2017 – yhtiön kasvu jatkui vahvana</a></p>
<p>15.2.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_15022018.pdf">Privanet Group Oyj – ennakkotietoja vuoden 2017 tuloksesta</a></p>
<p>31.1.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_31012018.pdf">Privanet Group Oyj:n osakemäärä on noussut suunnattujen osakeantien myötä 14.054.317 osakkeeseen</a></p>
<p>30.1.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_30012018.pdf">Juuso Enala on kutsuttu Privanet Groupin brändi- ja markkinointijohtajaksi</a></p>
<p>29.1.2018 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_290118.pdf">Privanet Group Oyj julkaisee tilinpäätöstiedotteen 28.2.2018 klo 8.00</a></p>
<p>30.12.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroup30122017.pdf">Privanet Securities Oy siirtynyt kokonaisuudessaan Privanet-konsernin omistukseen</a></p>
<p>15.12.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_15122017.pdf">Privanetin suunnattu osakeanti Nordic Growth Market Ab:lle (NGM AB) on maksettu 14.12.2017</a></p>
<p>8.12.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_08122017.pdf">Privanetin suunnattu osakeanti Nordic Growth Market Ab:lle (NGM Ab) etenee</a></p>
<p>28.11.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_28112017.pdf">Privanet säilyttää kuluvan vuoden liikevoittoennusteen ennallaan</a></p>
<p>15.11.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_15112017.pdf">Privanet Group Oyj:n hallitus on päättänyt jatkaa NGM:lle suunnatun maksullisen osakeannin merkintäaikaa </a></p>
<p>24.12.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_24102017.pdf">Privanet Group Oyj laskee liikkeeseen 2 miljoonan euron suuruisen vakuudettoman joukkovelkakirjalainan</a></p>
<p>11.10.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_11102017.pdf">Privanet Group Oyj:n ylimääräisen yhtiökokouksen päätökset 11.10.2017</a></p>
<p>2.10.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_02102017.pdf">Karri Salmi on valittu Privanet Capital Markets Oy:n hallituksen puheenjohtajaksi 2.10.2017 alkaen</a></p>
<p>21.9.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_21092017.pdf">Privanet on päättänyt keskeyttää rahastohankkeen toistaiseksi ja keskittyy aiemmin tiedotettujen kansainvälistymishankkeiden edistämiseen</a></p>
<p>20.9.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_kokouskutsu_200917.pdf">Kutsu Privanet Group Oyj:n ylimääräiseen yhtiökokoukseen</a></p>
<p>14.9.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_14092017_2.pdf">Karri Salmi Privanet Group Oyj:n toimitusjohtajaksi 1.10.2017 alkaen</a></p>
<p>14.9.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_14092017_1.pdf">Stuttgartin pörssin omistama Nordic Growth Market tavoittelee 10 %:n osuutta Privanetista: yhteistyöllä Privanet vahvistaisi asemaansa merkittävänä kansainvälisenä toimijana rahoitusalalla</a></p>
<p>29.8.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_29082017.pdf">Privanet Group Oyj:n puolivuosikatsaus tammi-kesäkuu 2017 – joukkorahoitus kasvaa ja kansainvälistyy</a></p>
<p>25.8.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_25082017_eng.pdf">Privanet Securities establishes crowdfunding platform FundedByMe in Finland and joins as part-owner in FundedByMe Finland</a></p>
<p>25.8.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_25082017.pdf">Privanet Securities merkittäväksi omistajaksi joukkorahoituspalvelu FundedByMe Finlandissa – aloittaa palvelun tarjoamisen Suomessa</a></p>
<p>31.3.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/PrivanetGroupOyj_yhtiotiedote_31032017.pdf">Privanet Group Oyj:n varsinaisen yhtiökokouksen päätökset 31.3.2017</a></p>
<p>3.3.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_kokouskutsu03032017.pdf">Kutsu Privanet Group Oyj:n varsinaiseen yhtiökokoukseen</a></p>
<p>28.2.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Liite_tilinpaatos2016_280217.pdf">Liite Privanet Group Oyj:n tilinpäätöstiedotteeseen 2016</a></p>
<p>28.2.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_28022017.pdf">Privanet Group Oyj:n tilinpäätöstiedote vuodelta 2016 – ripeä kasvu jatkui, yritysten kasvurahoituksen hakeminen aktiivista</a></p>
<p>7.2.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_Markkinatakaus_PrivanetGroupOyj_070217.pdf">Privanet Group Oyj:n markkinatakaussopimus S-Pankki Oy:n kanssa päättyy 7.3.2017</a></p>
<p>27.1.2017 - <a target="_blank" href="https://privanetgroup.fi/documents/270117_Yhtiotiedote_PrivanetGroupOyj.pdf">Privanet julkaisee tilinpäätöstiedotteen 28.2.2017 klo 8</a></p>
<p>14.12.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/141216_Yhtiotiedote_PrivanetGroupOyj.pdf">Korkeimmalta hallinto-oikeudelta päätös – markkinaoikeuden päätös Navi Group Oy:n (nyk. Privanet Capital Markets Oy) seuraamusmaksun alentamisesta pysyy voimassa</a></p>
<p>25.10.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_uusi_konserniyhtio25102016.pdf">Privanet Group perustaa Privanet Fund Management Oy:n – toimitusjohtajaksi Johannes Leppälä</a></p>
<p>16.9.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/160916_Yhtiotiedote_PrivanetGroupOyj.pdf">Muutoksia Privanet Groupin johdossa ja organisaatiossa – yhtiö perustaa strategia- ja kehitysyksikön</a></p>
<p>31.8.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/KORJAUS_Puolivuosikatsaus_1_6_16_PrivanetGroupOyj_160831.pdf">Privanet Group Oyj:n korjaus puolivuosikatsaukseen 1.1.-30.6.2016</a></p>
<p>31.8.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Puolivuosikatsaus_1_6_16_PrivanetGroupOyj_31082016-1.pdf">Privanet Group Oyj:n puolivuosikatsaus tammi-kesäkuu 2016 – liikevaihto ja liikevoitto kaksinkertaistuivat</a></p>
<p>25.8.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_250816.pdf">Privanet Group julkaisee puolivuosikatsauksen 31.8. klo 9</a></p>
<p>11.8.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_Rahasto_PrivanetGroupOyj.pdf">Privanet Group laajentaa toimintaansa rahastoihin</a></p>
<p>29.6.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_29.6.2016.pdf">Privanet Group laskee liikkeeseen 3 miljoonan euron joukkovelkakirjalainan</a></p>
<p>15.6.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_15062016.pdf">Kaupankäynti Privanet Group Oyj:n osakkeilla First North Finland -markkinapaikalla alkaa tänään 15.6.2016 klo 10.00</a></p>
<p>13.6.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_13062016_markkinatakaus.pdf">S-Pankki Privanet Group Oyj:n markkinatakaajaksi</a></p>
<p>9.6.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_PrivanetGroupOyj_9.6.2016.pdf">Privanet Group Oyj:n listautumisanti toteutui onnistuneesti</a></p>
<p>1.6.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_ylimerkinta_final010616.pdf">Privanet Group Oyj:n listautumisanti on ylimerkitty ja merkintäaika keskeytetään</a></p>
<p>20.5.2016 - <a target="_blank" href="https://privanetgroup.fi/documents/Yhtiotiedote_20.5.16-1.pdf">Privanet Group Oyj hakee kaupankäynnin kohteeksi First North -markkinapaikalle, käynnistää osakeannin ja julkaisee yhtiöesitteen</a></p>
<p>PRIVANET | 01.01.2018</p>
<p><a target="_blank" href="https://privanet.app/privanet-group/calendar" target="_blank">Paluu Sijoittajatiedotteisiin</a></p>
`

export default yhtiotiedotteet
