import React from 'react';
import Utils from './Utils/Utils';

const RenderTooltip = props => {
	if ( props && props !== null && typeof props !== 'undefined' && props.hasOwnProperty( 'payload' ) && props.payload !== null && typeof props.payload !== 'undefined' && props.payload.length > 0 && props.payload[0] !== null && props.active && props.payload[0].payload.date !== Utils.todayIso() ) {
		return (
			<div style={{ padding:'5px', backgroundColor:'white', fontSize:'11px', fontWeight:'bold', color:'#003c4e', border:'1px solid #003c4e' }} >{ Utils.isoDateToFi( props.payload[0].payload.date ) + ': ' + Utils.format( props.payload[0].payload.EUR, 2 ) + ' Eur' }</div>
		)
	} else {
		return null
	}
}

export default RenderTooltip
