import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import parse from 'html-react-parser';
import getArticleAndImage from '../News/getArticleAndImage';
import NewsCardBySlug from '../News/NewsCardBySlug';
import parseHtml from '../parseHtml/parseHtml';
import ActionButton from '../ActionButton/ActionButton';
import styled from 'styled-components'

const StyledFlexDiv = styled.div`
  display:block; padding:0 0 40px 0; background-color:#fff;
  @media only screen and ( min-width: 640px) {
      display:flex; padding:0; box-sizing:border-box;
  }
`;

const NewsBannerRight = props => {
  const { newsArticle, imageUrl } = getArticleAndImage( props.structure.columnSlug )
  let leadHtml = parseHtml( newsArticle.lead || '' )
  return (
  <Link to={ '/news/' + newsArticle.slug + '/' } >
  <StyledFlexDiv className="products-wrapper" >
    <div style={{ width:'100%', position:'relative', height:'480px', marginBottom:'40px', overflowY:'hidden', backgroundColor:'#fff', boxSizing:'border-box' }} >
      <div style={{ position:'absolute', top:0, right:0,  height:'480px', backgroundColor:'#E6F1F4', textAlign:'center', width:'100%', maxWidth:'640px', padding:'40px 40px 10px 40px', boxSizing:'border-box' }} >
        <h4 style={{ textTransform:'uppercase', fontWeight:'900', marginBottom:'40px', boxSizing:'border-box' }} >
          { newsArticle.title }
        </h4>
        <div style={{ boxSizing:'border-box' }} >{ leadHtml }</div>
        <div style={{ padding:'30px 0 0 0', boxSizing:'border-box' }} >
          <ActionButton title="LUE LISÄÄ" style={{ display:'inline-block', width:'120px' }} />
        </div>

      </div>
    </div>
    <div style={{ 
      width:'100%', height:'480px', position:'relative', 
      backgroundColor:'#fff', 
    }} >
      <div style={{ 
        width:'100%', maxWidth:'640px', height:'480px', position:'relative', 
        backgroundSize:'cover', backgroundPosition:'50% 0', 
        backgroundImage:'linear-gradient(rgba(21, 45, 85, 0.05), rgba(21, 45, 85, 0.15), rgba(21, 45, 85, 0.2), rgba(21, 45, 85, 0.05)), url('+(newsArticle.news_img || newsArticle.company_img)+')' 
      }} />
    </div>
  </StyledFlexDiv>
  </Link>
  )
}

export default NewsBannerRight
