var Utils = function() {

	return {

		format: function( amountIn, decimalCount = 2, decimal = ",", thousands = "." ) {
			try {

				let amount = Number( amountIn )

				decimalCount = Math.abs(decimalCount);
				decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

				const negativeSign = amount < 0 ? "-" : "";

				let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
				let j = (i.length > 3) ? i.length % 3 : 0;

				return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
			} catch (e) {
				console.log(e)
			}
		},

		replaceAll: function( str, find, replace ) {
			if ( typeof str === 'undefined' ) return ''
			const escapeRegExp = (str) => {
	    		return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
			}
    		return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
		},

		// compare two objects
		isEquivalent: function(a, b) {
		    var aProps = Object.getOwnPropertyNames(a);
		    var bProps = Object.getOwnPropertyNames(b);
		    if (aProps.length !== bProps.length) {
		        return false;
		    }
		    for (var i = 0; i < aProps.length; i++) {
		        var propName = aProps[i];
		        if (a[propName] !== b[propName]) {
		            return false;
		        }
		    }
		    return true;
		},

		// empty object
		isEmpty:function( obj ) {
		    for(var prop in obj) {
		        if(obj.hasOwnProperty(prop))
		            return false;
		    }
		    return JSON.stringify(obj) === JSON.stringify({});
		},

		// var is a number
		isNumber: function( o ) {
		  return ! isNaN (o-0) && o !== null && o !== "" && o !== false;
		},


		isValidDate: function( d ) {
		  return d instanceof Date && !isNaN(d);
		},

		// convetr tg ISO date to JavaScript Date
		isoToDate: function( iso ) {
		    var date1 = iso.length > 10 ? iso.substring(0, iso.indexOf( '+' ) ) : iso
		    var date2 = date1.replace( new RegExp( '-', 'g' ) , '/' );
		    return Date.parse( date2 )
		},

		// convetr fi date to ISO Date
		fiDateToIso: function( datestring ) {
			var datestringArr = datestring.split( '.' );
			if ( datestringArr.length < 2 ) return false; // date not in three parts
			if ( !Utils.isNumber( datestringArr[ 2 ] ) || !Utils.isNumber( datestringArr[ 1 ] ) || !Utils.isNumber( datestringArr[ 0 ] ) ) return false
			var dateIsoString = datestringArr[ 2 ] + '-' + datestringArr[ 1 ] + '-' + datestringArr[ 0 ]; // reverse date order
			var dateIso = Date.parse( dateIsoString ); // convert string to date
			if ( !( dateIso > 0 )) return false; // date not valid  
			return dateIsoString;
		},

		formatDatetoFi: function( today = new Date() ) {
			return today.toLocaleString( 'fi-FI', { year: 'numeric', month: 'numeric', day: 'numeric' } );
		},

		// convetr ISO date to fi Date
		isoDateToFi: function( datestring ) {
			//console.log( 'isoDateToFi', datestring )
			if ( typeof datestring === 'undefined' || datestring === '' || datestring === null ) return ''
			var datestringArr = datestring.substr(0,10).split('-');
			if (datestringArr.length < 2) return false; // date not in three parts
			var dateFiString = datestringArr[2] + '.' + datestringArr[1] + '.' + datestringArr[0]; // reverse date order
			return dateFiString;
		},

		today: function() {
			return new Date();
		},

		todayIso: function( today = new Date() ) {
			// let today = new Date();
			let dd = today.getDate();
			let mm = today.getMonth()+1; 
			let yyyy = today.getFullYear();
			if(dd<10) {
			    dd = '0'+dd
			} 
			if(mm<10) {
			    mm = '0'+mm
			} 
			return yyyy + '-' + mm + '-' + dd;
		},

		addDays: function( date, days ) {
			var result = new Date( date );
			result.setDate( result.getDate() + days );
			return result;
		},

		// post a form, needed in banking integrations
		postAsForm: function( path, params, method, target ) {
		    method = method || "post"; // Set method to post by default if not specified.
		    var form = document.createElement( "form" );
		    form.setAttribute( "method", method );
		    form.setAttribute( "action", path );
		    if ( typeof target != 'undefined') form.setAttribute( "target", target ); // e.g. '_blank'
		    for( var key in params ) {
		        if ( params.hasOwnProperty( key ) ) {
		            var hiddenField = document.createElement( "input" );
		            hiddenField.setAttribute( "type", "hidden" );
		            hiddenField.setAttribute( "name", key );
		            hiddenField.setAttribute( "value", params[key] );
		            form.appendChild( hiddenField );
		         }
		    }
		    document.body.appendChild(form);
		    form.submit();
		    document.body.removeChild(form);
		}

	} // return

}();

export default Utils

