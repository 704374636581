import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../ActionButton/ActionButton';


const SecurityButtons = props => {
	//console.log( 'securityButtons props', props )
	return (
		<div style={{ width:'100%', backgroundColor:'white', margin:'20px 0', textAlign:'center' }} >
			<div style={{ display:'inline-block', boxSizing:'border-box', backgroundColor:'white', textAlign:'center', padding:'10px 0 20px 20px' }} >


				{ props.rowData && props.rowData != null && props.rowData.website && props.rowData.website.trim() !== ''  &&
					<a href={ props.rowData.website } target='_blank' >
						<ActionButton 
							title="Yrityksen kotisivulle" 
							style={{ display:'inline-block', width:'215px', margin:'12px 12px 0 0', background:'linear-gradient(180deg, #6b6b72 0%, #446 100%)' }} 
						/>
					</a>
				}

				{ props.rowData && props.rowData != null && props.rowData.brochure && props.rowData.brochure.trim() !== '' &&
					<a href={ props.rowData.brochure } target='_blank' >
						<ActionButton 
							title={ props.currentTab !== 'BONDS' ? 'Kohdeyhtiöanalyysi' : 'Lainaehdot' } 
							style={{ display:'inline-block', width:'205px', margin:'12px 12px 0 12px', background:'linear-gradient(180deg, #90a4ae 0%, #455a64 100%)' }} 
						/>
					</a>
				}

				{ props.currentTab !== 'BONDS' && 
					<a href='https://privanet.fi/premarket/' target='_blank' >
						<ActionButton 
							title="OSTA/MYY ARVOPAPERIA" 
							style={{ display:'inline-block', width:'218px' }} 
						/>
					</a>
				}

				{ props.rowData && props.rowData != null && props.rowData.description && props.rowData.description != null && props.rowData.description.alerturl &&
					<button className="solid" style={{ display:'inline-block', cursor:'pointer', margin:'5px' }} onClick={ e => { window.open( props.rowData.description.alerturl ) } } >HUOM</button>
				}

			</div>
		</div>
	)
}

export default SecurityButtons
