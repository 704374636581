import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import './css/fonts.css';
import './css/commonStyles.css';
import Request from '../Request/Request';
import Store from '../Store/Store';
import { Tabs, MainTable, Group } from './Ui/Ui';
import ActionButton from '../ActionButton/ActionButton';

class Equities extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			//items: Store.get( 'items' ),
			tmp:null, 
		};
	    this.setRoute = this.setRoute.bind(this);
	    this.checkTradeStop = this.checkTradeStop.bind(this);
	}

	checkTradeStop( stockList ) {
		stockList && stockList.length > 0 && stockList.map( r => {
			let tradeStopOn = false
			if ( r && r.hasOwnProperty( 'description' ) ) {
				let description = r.description,
					tradestoptext = '',
					tradestop = new Date( '1975-01-01' ),
					tradestart = new Date( '2050-01-01' )
				if ( description != null ) {
				    tradestoptext = description.tradestoptext || '';
				    tradestop = description.tradestop ? new Date( description.tradestop ) : new Date( '1975-01-01' );
				    tradestart = description.tradestart ? new Date( description.tradestart ) : new Date( '2050-01-01' );
				}
				tradeStopOn = ( tradestoptext !== '' && Date.now() > tradestop && Date.now() < tradestart )
			}
			if ( tradeStopOn ) {
				r.buy = null
				r.sell = null
			}
			if ( r && r.hasOwnProperty( 'pb' ) && Number( r.pb ) > 99 ) { r.pb = null }
			if ( r && r.hasOwnProperty( 'pe' ) && Number( r.pe ) > 99 ) { r.pe = null }
			if ( r && r.hasOwnProperty( 'ps' ) && Number( r.ps ) > 99 ) { r.ps = null }
		} )

	}

	componentDidMount() {
    	//document.documentElement.scrollTop = 0; 
    	//document.body.scrollTop = 0; // for Safari
		Request.get( 
			"stocks", 
			result => {
				// clear buy and sell in case tradeStop
				this.checkTradeStop( result.data.SU.stocks ) 
				this.checkTradeStop( result.data.PK.stocks ) 
				this.checkTradeStop( result.data.MU.stocks ) 
				Store.set( 'premarket.stocklist.items', result.data ) 
				this.setState( { tmp:null } )
			},
			null,
			"https://privanet.fi/premarket/api/"
		)
	}

	setRoute( route ) {
		this.props.history.push( route )
		this.setState( { tmp:null } )
	}

	render( props ) {
		let withTabs = this.props.tabs || false
		//let currentTab = this.props.match.params.slug || 'SU'
		let currentTab = this.props.slug || 'SU'
		//let items = this.state.items
		let items = Store.get( 'premarket.stocklist.items' )
		//const stockGroups = [ 'SU', 'MU', 'BONDS' ]
		//const stockGroupTitles = [ 'Päälista', 'Meklarilista', 'JVK-lista' ]
		const stockGroups = [ 'SU', 'MU' ]
		const stockGroupTitles = [ 'Päälista', 'Meklarilista' ]
		const mainStockColumns = [
			{ key:'name', title:'Yritys', type:'text', className:'hide-on-mobile' },
			{ key:'abbreviation', type:'text', title:'Yritys', className:'hide-on-dt' },
			{ key:'quotation', title:'Noteeraus', type:'number', className:'show-on-small' },
			{ key:'value', title:'Markkina arvo (MEUR)', type:'number', className:'show-on-small' },
		]
		const stockColumns = [
			{ key:'name', title:'Yritys', type:'text', className:'hide-on-mobile' },
			{ key:'abbreviation', type:'text', title:'Yritys', className:'hide-on-dt' },
			{ key:'buy', title:'Osto', type:'number', className:'show-on-medium' },
			{ key:'sell', title:'Myynti', type:'number', className:'show-on-medium' },
			{ key:'quotation', title:'Viim.', type:'number', className:'show-on-small' },
			{ key:'value_date', title:'Päivä', type:'date', className:'show-on-medium' },
			{ key:'value', title:'Markkina arvo (MEUR)', type:'number', className:'show-on-large' },
			{ key:'turnover', title:'Liikevaihto (MEUR)', type:'number', className:'show-on-large' },
			{ key:'result', title:'Tulos (MEUR)', type:'number', className:'show-on-large' },
			//{ key:'divident', title:'Osinko (EUR)', type:'number', className:'show-on-large' },
			//{ key:'ps', title:'PS', type:'number', className:'show-on-xl' },
			//{ key:'pe', title:'PE', type:'number', className:'show-on-xl' },
			//{ key:'pb', title:'PB', type:'number', className:'show-on-xl' },
		]
		const bondColumns = [
			{ key:'name', title:'Arvopaperi', type:'text', className:'hide-on-mobile' },
			{ key:'abbreviation', type:'text', title:'Yritys', className:'hide-on-dt' },
			{ key:'buy', title:'Osto', type:'number' },
			{ key:'sell', title:'Myynti', type:'number' },
			{ key:'coupon_rate', title:'Kuponki%', type:'number', className:'show-on-small' },
			{ key:'maturity_date', title:'Eräpäivä', type:'date', className:'show-on-medium' },
			{ key:'coupon_frequency', title:'Koronmaksu', type:'text', className:'show-on-medium' },
			{ key:'denomination', title:'Litterakoko', type:'text', className:'show-on-large' },
			{ key:'isin_code', title:'ISIN', type:'text', className:'show-on-large' },
		]

		let itemsLoaded = ( stockGroup ) => {
			let stockGroupCheck = stockGroup || 'SU'
			return items && typeof items !== 'undefined' && items != null && items.hasOwnProperty( stockGroupCheck ) && items[ stockGroupCheck ].stocks != null && items[ stockGroupCheck ].stocks.length > 0 ; 
		}

		let showTabs = this.props.hasOwnProperty( "showTabs" ) ? this.props.showTabs : true 
		let showPk = this.props.hasOwnProperty( "showPk" ) ? this.props.showPk : true 

		return (
			<div className="products" style={ this.props.style } >
			
				{ showTabs 
				?	<div style={{ backgroundColor:'white', marginTop:"10px", textAlign:'center', paddingTop:0 }} >
						<Tabs 
							keys={ stockGroups } 
							titles={ stockGroupTitles } 
							baseUrl="/stock/" 
							curTabKey={ currentTab }
							renderTab={ itemsLoaded }
						/> 
					</div>
				:	<div style={{ backgroundColor:'white', marginTop:"20px", paddingTop:0 }} >
					</div>
				}

				<MainTable>
					{ itemsLoaded() && 
						<Fragment>
							{ itemsLoaded( currentTab ) && 
							<Group 
								id={ currentTab } 
								tab={ currentTab }
								columns={ currentTab === 'BONDS' ? bondColumns : ( showPk ? stockColumns : mainStockColumns ) } 
								staticlink={ "/stock/" + currentTab + "/" }
								linkattribute="abbreviation"
								setRoute={ this.setRoute }
								title={ this.props.title || items[ currentTab ].fi }
								data={ items[ currentTab ].stocks }
							/> }
							{ !showPk && 
								<tr><td colSpan="8" >
									<Link to="/stock" >
										<div style={{ padding:'20px 0 20px 0', boxSizing:'border-box' }} >

											<ActionButton 
												title="AVAA NOTEERAUKSET" 
												style={{ display:'inline-block', width:'205px', margin:'12px 12px 12px 12px', background:'linear-gradient(180deg, #90a4ae 0%, #455a64 100%)' }} 
											/>

										</div>
									</Link>
								</td></tr>
							}

							{ itemsLoaded( currentTab ) && currentTab === 'BONDS' &&
								<tr><td colSpan="8" >
								<div style={{ padding:'20px 0 10px 5px', textAlign:'left', fontWeight:'bold' }} >
									Kiinnostuitko JVK-listan kohteista? Ota yhteyttä 
									<span style={{ padding:'3px 0', margin:'0 5px', borderBottom:'1px solid black' }}  >
										<a href="mailto:bonds@privanet.fi">
										bonds@privanet.fi
										</a>
									</span>jättääksesi toimeksiannon tai saadaksesi lisätietoja.
								</div>
								</td></tr>
			    			}

							{ itemsLoaded( currentTab ) && currentTab === 'SU' && showPk &&
							<Fragment>
							<tr><td><div style={{ width:'20px', height:'20px' }} /></td></tr>
							<Group 
								id={ 'PK' } 
								tab={ 'PK' }
								columns={ stockColumns } 
								staticlink={ "/stock/" + 'PK' + "/" }
								linkattribute="abbreviation"
								setRoute={ this.setRoute }
								title={ 'Pienet ja keskisuuret' }
								data={ items[ 'PK' ].stocks }
							/> 
							</Fragment> }
						</Fragment> 
					}
				</MainTable>
			</div>
		)
	}
}

export default Equities

