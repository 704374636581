import axios from 'axios';

var Request = function() {

	const apiRoot = "https://privanetgroup.fi/api/"
	//const apiRoot = "https://privanet.fi/web/"

	let handleError = ( error, onError ) => {
		if ( typeof onError !== 'undefined' && onError !== null ) {
			onError( error )
		} else {
			console.error( error )
		}
	} 

	return {
		get:function( api, onSuccess, onError, endpoint ){
			axios( {
		        method: 'GET',
		        url: ( endpoint || apiRoot ) + api,
		        headers: {
		            "Content-Type": "application/json",
		        },
			} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		xhr:function( method, api, data, onSuccess, onError ){
			axios( {
		        method: method,
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		post:function( api, data, onSuccess, onError ){
			axios( {
		        method: 'POST',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		put:function( api, data, onSuccess, onError ){
			axios( {
		        method: 'PUT',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		delete:function( api, data, onSuccess, onError ){
			axios( {
		        method: 'DELETE',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
	}

}();

export default Request

